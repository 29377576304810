import React from 'react';
import { toAbsoluteUrl } from '_metronic/_helpers';

export function FooterCompact() {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          style={{ width: '134.82px', height: '15.72px', marginBottom: '35px' }}
          alt="PoweredByQwerty"
          src={toAbsoluteUrl('/media/svg/poweredBy.svg')}
        />
      </div>
    </>
  );
}
