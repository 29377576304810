// hooks
import { useQuery } from '@tanstack/react-query';
import apiRequest from '../../config/apiRequest';

const useTariffsRequest = () => {
  const {
    isLoading,
    data: tariffs,
    refetch: refetchTariffs
  } = useQuery(['getTariffs'], () =>
    apiRequest({
      method: 'GET',
      endpoint: 'org/tariff'
    })
  );

  return {
    tariffs: tariffs?.data?.rows || [],
    tariffsIsLoading: isLoading,
    refetchTariffs: refetchTariffs
  };
};

export default useTariffsRequest;
