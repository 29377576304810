import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

// utils
import { calculateTempDifference } from './utils';
import { numbersWithSpaces } from 'app/helpers/numberWithSpaces';
import { toAbsoluteUrl } from '_metronic/_helpers';

export default function ExpenditureDetails({ devices }) {
  const [heatingConsumption, setHeatingConsumption] = useState(0);
  const [coolingConsumption, setCoolingConsumption] = useState(0);
  const [waterVolumeHeating, setWaterVolumeHeating] = useState(0);
  const [waterVolumeCooling, setWaterVolumeCooling] = useState(0);

  useEffect(() => {
    if (devices?.length > 0) {
      const heatingCons = devices
        .filter(item => item.deviceType === 'HEATING')
        .map(e =>
          e.id === 121
            ? e.billingInfo.deviceInfo.totalEnergyDiff
            : e.billingInfo.deviceInfo.totalEnergy
        );

      let sumHeatingConsumption = 0;
      for (let i = 0; i < heatingCons.length; i++) {
        sumHeatingConsumption += heatingCons[i];
      }
      setHeatingConsumption(sumHeatingConsumption);

      const heatingWater = devices
        .filter(item => item.deviceType === 'HEATING' && item.ActiveDevice)
        .map(e =>
          e.ActiveDevice?.last_flow_rate && e.ActiveDevice?.last_flow_rate < 0
            ? e.ActiveDevice?.last_flow_rate * -1
            : e.ActiveDevice?.last_flow_rate
        );

      let sumHeatingWater = 0;
      for (let i = 0; i < heatingWater.length; i++) {
        sumHeatingWater += heatingWater[i];
      }
      setWaterVolumeHeating(sumHeatingWater.toFixed(2));

      const coolingCons = devices
        .filter(item => item.deviceType === 'COOLING')
        .map(e => e.billingInfo.deviceInfo.totalEnergy);
      let sumCoolingConsumption = 0;
      for (let i = 0; i < coolingCons.length; i++) {
        sumCoolingConsumption += coolingCons[i];
      }
      setCoolingConsumption(sumCoolingConsumption);

      const coolingWater = devices
        .filter(item => item.deviceType === 'COOLING' && item.ActiveDevice)
        .map(e =>
          e.ActiveDevice.last_flow_rate < 0
            ? e.ActiveDevice.last_flow_rate * -1
            : e.ActiveDevice.last_flow_rate
        );
      let sumCoolingWater = 0;
      for (let i = 0; i < coolingWater.length; i++) {
        sumCoolingWater += coolingWater[i];
      }
      setWaterVolumeCooling(sumCoolingWater.toFixed(2));
    }
  }, [devices]);

  return (
    <>
      <div className="card card-custom">
        <div className="card-header bg-white border-0 pt-5 expenditure-details-border">
          <h3 className="card-title expenditure-details-title">
            <FormattedMessage id="EXPENDITURE.DETAILS" />
          </h3>
        </div>
        <div className="card-body d-flex bg-white flex-column p-0">
          <span className="exp-details-img">
            <SVG
              src={toAbsoluteUrl('/media/svg/chart.svg')}
              className=" align-self-center"
            />
          </span>
          <div className="card-spacer bg-white exp-details-div">
            <div className="row">
              <div className="col py-6 mr-0">
                <div className="font-size-sm text-muted font-weight-bold">
                  <FormattedMessage id="HEATING.CONSUMPTION" />
                </div>
                <div className="font-size-h4 font-weight-bolder orange">
                  {heatingConsumption < 0
                    ? numbersWithSpaces((0).toFixed(2))
                    : numbersWithSpaces(heatingConsumption.toFixed(2))}{' '}
                  GJ
                </div>
              </div>
              <div className="col py-6">
                <div className="font-size-sm text-muted font-weight-bold">
                  <FormattedMessage id="COOLING.CONSUMPTION" />
                </div>
                <div className="font-size-h4 font-weight-bolder blue">
                  {numbersWithSpaces(coolingConsumption?.toFixed(2))} GJ
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col py-6 mr-0">
                <span className="font-size-sm text-muted font-weight-bold">
                  <FormattedMessage id="WATER.VOLUME" />
                </span>
                <div className="font-size-h4 font-weight-bolder orange">
                  {numbersWithSpaces(waterVolumeHeating)} m<sup>3</sup>
                </div>
              </div>
              <div className="col py-6">
                <span className="font-size-sm text-muted font-weight-bold">
                  <FormattedMessage id="WATER.VOLUME" />
                </span>
                <div className="font-size-h4 font-weight-bolder blue">
                  {numbersWithSpaces(waterVolumeCooling)} m<sup>3</sup>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col py-6 mr-0">
                <span className="font-size-sm text-muted font-weight-bold">
                  <FormattedMessage id="TEMP.DIFF" />
                </span>
                <div className="font-size-h4 font-weight-bolder orange">
                  {calculateTempDifference({ devices })?.heatingTempDiff}{' '}
                  <sup>0</sup>C
                </div>
              </div>
              <div className="col py-6">
                <span className="font-size-sm text-muted font-weight-bold">
                  <FormattedMessage id="TEMP.DIFF" />
                </span>
                <div className="font-size-h4 font-weight-bolder blue">
                  {calculateTempDifference({ devices })?.coolingTempDiff}{' '}
                  <sup>0</sup>C
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
