import { Box, Card, CardContent, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useState } from 'react';
import * as Yup from 'yup';
import SVG from 'react-inlinesvg';
import axios from 'axios';
import moment from 'moment';
import { toAbsoluteUrl } from '_metronic/_helpers';
import { baseUrl } from 'app/config/baseUrl';

export default function DeleteTenantForm(props) {
  const [status, setStatus] = useState();
  const id = props.id;

  const success = () => (
    <Box className="tenantwasadded">
      <div className="column-center">
        <span className="margin-top-50">
          <SVG src={toAbsoluteUrl('/media/svg/done.svg')} />
        </span>
        <p className="font-weight-bolder margin-top-50 font-16">
          Tenant was deleted
        </p>
      </div>
    </Box>
  );

  const error = () => (
    <Box className="tenantwasadded">
      <div className="column-center">
        <span className="margin-top-50">
          <SVG
            src={toAbsoluteUrl('/media/svg/Modal/deactivation-tenant.svg')}
          />
        </span>
        <p className="font-weight-bolder margin-top-50 font-16">
          Tenant was not deleted
        </p>
      </div>
    </Box>
  );

  function check() {
    if (status === undefined) {
      return null;
    } else if (status === 200) {
      return success();
    } else {
      return error();
    }
  }

  return (
    <Card className="tenantcontainer">
      <CardContent>
        <FormikStepper
          className="tenantform"
          initialValues={{
            deactivation_date: ''
          }}
          onSubmit={async values => {
            axios
              .post(`${baseUrl}/org/tenant/${id}/deactivate`, {
                deactivation_date: moment(values.deactivation_date).format(
                  'YYYY-MM-DD'
                )
              })
              .then(response => {
                setStatus(response.status);
                console.log(response);
              })
              .catch(e => {
                setStatus(e.response.status);
                console.log(e.response);
              });
          }}
        >
          <FormikStep
            validationSchema={Yup.object({
              deactivation_date: Yup.string().required('Required')
            })}
          >
            <Box paddingBottom={2}>
              <p className="input-p">Deactivation Date</p>
              {/* <br /> */}
              <Field
                InputProps={{ disableUnderline: true }}
                className="deactivation-input"
                type="date"
                name="deactivation_date"
                component={TextField}
              />
            </Box>
          </FormikStep>

          <FormikStep>{check()}</FormikStep>
        </FormikStepper>
      </CardContent>
    </Card>
  );
}

export function FormikStep({ children }) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  function isLastStep() {
    return step === 0;
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          props.onSubmit(values, helpers);
          setStep(s => s + 1);
        } else {
          setStep(s => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      <Form autoComplete="off">
        {step < 1 ? (
          <div>
            <div className="margin-top-30 d-flex justify-content-center">
              <SVG
                src={toAbsoluteUrl('/media/svg/Modal/deactivation-tenant.svg')}
              />
            </div>
            <p className="deactivation-tenant"> Deactivation Tenant</p>
            <hr className="width-100p" />
          </div>
        ) : null}
        {currentChild}
        {step < 1 ? (
          <Grid container style={{ marginTop: 50 }} spacing={2}>
            <Grid item>
              <button className="form-next-button1" type="submit">
                Next
              </button>
            </Grid>
          </Grid>
        ) : null}
      </Form>
    </Formik>
  );
}
