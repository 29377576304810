import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '_metronic/_helpers';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { baseUrl } from 'app/config/baseUrl';

export default function EditUserForm({ user, close, userId }) {
  const formikRef = useRef();
  let params = useParams();

  useEffect(() => {
    let data = {
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      phone: user.phone
    };
    formikRef.current.setValues(data);
  }, [userId]);

  return (
    <Card className="edit-user-container">
      <CardContent>
        <FormikStepper
          innerRef={formikRef}
          className="tenantform"
          close={close}
          initialValues={{
            firstName: '',
            lastName: '',
            username: '',
            phone: ''
          }}
          onSubmit={async values => {
            axios
              .put(
                `${baseUrl}/org/tenant/${params.id}/users/${user.id}`,
                values
              )
              .then(response => {
                // console.log(response.data)
              })
              .catch(e => {
                console.log(e.response);
              });
          }}
        >
          <FormikStep>
            <div className="edit-user-form-container">
              <div>
                <Box paddingBottom={2}>
                  <span className="input-p">
                    <FormattedMessage id="FIRST.NAME" />
                  </span>
                  <br />
                  <Field
                    InputProps={{ disableUnderline: true }}
                    name="firstName"
                    className="edituserinput"
                    component={TextField}
                  />
                </Box>
                <Box paddingBottom={2}>
                  <span className="input-p">
                    <FormattedMessage id="USERNAME" />
                  </span>
                  <br />
                  <Field
                    InputProps={{ disableUnderline: true }}
                    className="edituserinput"
                    name="username"
                    component={TextField}
                  />
                </Box>
              </div>
              <div>
                <Box paddingBottom={2}>
                  <span className="input-p">
                    <FormattedMessage id="LAST.NAME" />
                  </span>
                  <br />
                  <Field
                    InputProps={{ disableUnderline: true }}
                    name="lastName"
                    className="edituserinput"
                    component={TextField}
                  />
                </Box>
                <Box paddingBottom={2}>
                  <span className="input-p">
                    <FormattedMessage id="PHONE" />
                  </span>
                  <br />
                  <Field
                    InputProps={{ disableUnderline: true }}
                    className="edituserinput"
                    name="phone"
                    component={TextField}
                  />
                </Box>
              </div>
            </div>
          </FormikStep>

          <FormikStep>
            <Box className="tenantwasadded">
              <div className="column-center">
                <span style={{ marginTop: 30 }}>
                  <SVG src={toAbsoluteUrl('/media/svg/done.svg')} />
                </span>
                <Typography
                  className="font-weight-bolder"
                  style={{ marginTop: 30, fontSize: 16 }}
                >
                  <FormattedMessage id="USER.WAS.EDITED" />
                </Typography>
              </div>
            </Box>
          </FormikStep>
        </FormikStepper>
      </CardContent>
    </Card>
  );
}

export function FormikStep({ children }) {
  return <>{children}</>;
}

export function FormikStepper({ close, children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  function isLastStep() {
    return step === 0;
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setStep(s => s + 1);
        } else {
          setStep(s => s - 1);
          helpers.setTouched({});
        }
      }}
    >
      <Form autoComplete="off">
        {step < 1 ? (
          <div>
            <Box
              style={{
                marginTop: 0,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <SVG src={toAbsoluteUrl('/media/svg/Userimg.svg')}></SVG>
            </Box>
            <p className="enterdetailsmodal">
              <FormattedMessage id="EDIT.INFO" />
            </p>
          </div>
        ) : null}
        {currentChild}
        {step === 0 ? (
          <Grid container style={{ marginTop: 20 }} spacing={5}>
            <Grid item>
              <button
                onClick={close}
                type="button"
                className="edit-user-form-back-btn"
              >
                <FormattedMessage id="BACK" />
              </button>
            </Grid>
            <Grid item>
              <button className="edit-user-form-save-btn" type="submit">
                <FormattedMessage id="SAVE" />
              </button>
            </Grid>
          </Grid>
        ) : null}
      </Form>
    </Formik>
  );
}
