import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import axios from 'axios';
import * as _redux from './redux';
import store, { persistor } from './redux/store';
import App from './app/App';
import './index.scss';
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
// Datepicker
import 'react-datepicker/dist/react-datepicker.css';
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from './_metronic/layout';
import { MetronicI18nProvider } from './_metronic/i18n';
import ReactDOM from 'react-dom/client';
import './app/App.css';
import 'antd/dist/antd.css';

const { PUBLIC_URL } = process.env;

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

_redux.setupAxios(axios, store);

root.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>
);
