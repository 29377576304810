/* eslint-disable no-unused-vars */

// hooks
import React, { useEffect, useMemo, useState } from 'react';
import objectPath from 'object-path';
import { FormattedMessage } from 'react-intl';

// components
import Chart from 'react-apexcharts';
import YearSelector from 'app/components/selector/YearSelector';
import MonthSelector from 'app/components/selector/MonthSelector';
import AlertModal from 'app/components/modals/AlertModal';
import Button from '../../../components/button/Button';

// helpers
import { toAbsoluteUrl } from '_metronic/_helpers';
import { useHtmlClassService } from '../../../../_metronic/layout';

// utils
import {
  exportInvoice,
  getMonths,
  getSeries,
  getTenantInvoices,
  monthsGjhData
} from '../utils';
import moment from 'moment';

// custom hooks
import useTenantPageRequest from '../../../components/hooks/useTenantPageRequest';
import { useMyInfoRequest } from '../../../components/hooks/useMyInfoRequest';

export default function ExpenditureHistory({
  tenantId,
  getTenantId,
  heatFixed
}) {
  const [maxHeating, setMaxHeating] = useState(0);
  const [chartOptions, setChartOptions] = useState({});
  const [selectedPeriod, setSelectedPeriod] = useState('year');
  const [loadingChart, setLoadingChart] = useState(true);
  const [month, setMonth] = useState(
    moment()
      .subtract(1, 'months')
      .format('YYYY-MM')
  );
  const [year, setYear] = useState(
    moment()
      .subtract(1, 'months')
      .format('YYYY')
  );
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState('');
  const [tenantDevicesInfo, setTenantDevicesInfo] = useState([]);

  const monthsGjhDataItems = Object.values(monthsGjhData);

  // custom hooks
  const {
    tenantDevicesFlow,
    tenantDevicesFlowIsLoading
  } = useTenantPageRequest({ tenantId, getTenantId: getTenantId() });

  const { myInfo } = useMyInfoRequest();

  useEffect(() => {
    if (tenantDevicesFlow && !tenantDevicesFlowIsLoading) {
      if (myInfo?.Organization?.id === 1) {
        const filteredMonths = tenantDevicesFlow.filter(
          item =>
            item.month.includes('2022') &&
            item.month !== '2022-01' &&
            item.month !== '2022-02'
        );
        setTenantDevicesInfo(filteredMonths);
      } else {
        const filteredMonths = tenantDevicesFlow.filter(
          item => item.month > '2023-03'
        );
        setTenantDevicesInfo(filteredMonths);
      }
      setLoadingChart(false);
    }
  }, [tenantDevicesFlow, tenantDevicesFlowIsLoading]);

  useEffect(() => {
    let maxHeat = 0;
    if (tenantDevicesInfo) {
      tenantDevicesInfo.forEach(item => {
        if (monthsGjhData.hasOwnProperty(item.month)) {
          monthsGjhData[item.month][item.Device.deviceType] += item.heat_flow;
        }
      });
      Object.values(monthsGjhData).forEach(item => {
        if (item.HEATING > maxHeat) {
          maxHeat = item.HEATING;
        }
      });
      // setLoadingChart(false);
    }
    setMaxHeating(maxHeat);
  }, [tenantDevicesInfo]);

  useEffect(() => {
    getTenantInvoices({ getTenantId, tenantId, monthsGjhData });
  }, []);

  useEffect(() => {
    if (heatFixed || heatFixed === 0) {
      setChartOptions(
        getChartOption(
          layoutProps,
          getMonths(selectedPeriod),
          undefined,
          heatFixed
        )
      );
    }
  }, [heatFixed, selectedPeriod]);

  function getChartOption(layoutProps, months) {
    const options = {
      chart: {
        type: 'bar',
        height: 200,
        toolbar: {
          show: false
        },
        sparkline: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          endingShape: 'rounded',
          borderRadius: 3
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['transparent']
      },
      xaxis: months,
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: '12px',
          fontFamily: layoutProps.fontFamily
        },
        y: {
          formatter: function(value, data) {
            let monthsGjhDataItemsCopy = [...monthsGjhDataItems];
            if (selectedPeriod === '6month') {
              monthsGjhDataItemsCopy = monthsGjhDataItemsCopy.slice(0, 6);
            } else if (selectedPeriod === '3month') {
              monthsGjhDataItemsCopy = monthsGjhDataItemsCopy.slice(0, 3);
            }
            monthsGjhDataItemsCopy.reverse();
            return (
              '<div class="arrow_box"><span>' +
              value +
              'GJ</span><br/><span>' +
              (data.seriesIndex === 0
                ? monthsGjhDataItemsCopy[data.dataPointIndex]
                    .heatingFinalCost || 0
                : monthsGjhDataItemsCopy[data.dataPointIndex]
                    .coolingFinalCost || 0) +
              ' (₾)</span></div>'
            );
          }
        },
        marker: {
          show: true
        }
      },

      colors: ['#FFA800', '#3699FF'],
      grid: {
        borderColor: layoutProps.colorsGrayGray200,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          left: 20,
          right: 20
        }
      }
    };
    return options;
  }

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        'js.colors.gray.gray500'
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        'js.colors.gray.gray200'
      ),

      fontFamily: objectPath.get(uiService.config, 'js.fontFamily')
    };
  }, [uiService]);

  return (
    <>
      {alert && <AlertModal alert={alert} setAlert={setAlert} />}
      <div className="card card-custom">
        <div className="card-header exp-scroll bg-white border-0 chart-div-border pt-5">
          <div className="d-flex mb-4 flex-column">
            <h1 className="expenditure-history-title">
              <FormattedMessage id="EXPENDITURE.HISTORY" />
            </h1>
            <div className="chart-menu">
              <button
                className={`chart-menu-item ${
                  selectedPeriod === 'year' ? 'chart-menu-item-selected' : ''
                }`}
                onClick={() => {
                  setSelectedPeriod('year');
                }}
              >
                <FormattedMessage id="YEAR" />
              </button>
              <button
                className={`chart-menu-item ${
                  selectedPeriod === '6month' ? 'chart-menu-item-selected' : ''
                }`}
                onClick={() => {
                  setSelectedPeriod('6month');
                }}
              >
                <FormattedMessage id="6MONTH" />
              </button>
              <button
                className={`chart-menu-item ${
                  selectedPeriod === '3month' ? 'chart-menu-item-selected' : ''
                }`}
                onClick={() => {
                  setSelectedPeriod('3month');
                }}
              >
                <FormattedMessage id="3MONTH" />
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <YearSelector
              onChange={e => setYear(e.target.value)}
              value={year}
            />
            <MonthSelector
              onChange={e => setMonth(e.target.value)}
              value={month}
              year={year}
            />
            <Button
              containerStyle="chart-menu-item excel"
              label="DOWNLOAD.INVOICE"
              spinnerColor="success"
              loading={isLoading}
              onClick={() =>
                exportInvoice({ getTenantId, month, setIsLoading, setAlert })
              }
            ></Button>
          </div>
          <div className="card-body d-flex bg-white p-0">
            <div className="col-lg-11 height-300">
              {loadingChart ? (
                <div className="d-flex justify-content-center exp-loading align-items-center">
                  <img
                    className="qwerty-loading-img"
                    src={toAbsoluteUrl('/media/qwerty-loading.gif')}
                    alt="Qwerty logo"
                  />
                </div>
              ) : (
                <Chart
                  style={{ maxWidth: 800, minWidth: 400 }}
                  options={chartOptions}
                  series={getSeries(selectedPeriod)}
                  type="bar"
                  height="300"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
