const years = () => {
  let startYear = 2022;
  const endDate = new Date().getFullYear();
  let yearsArr = [];

  for (var i = startYear; i <= endDate; i++) {
    yearsArr.push(startYear);
    startYear++;
  }
  return yearsArr;
};

const months = [
  {
    label: 'January',
    value: '01'
  },
  {
    label: 'February',
    value: '02'
  },
  {
    label: 'March',
    value: '03'
  },
  {
    label: 'April',
    value: '04'
  },
  {
    label: 'May',
    value: '05'
  },
  {
    label: 'Jun',
    value: '06'
  },
  {
    label: 'July',
    value: '07'
  },
  {
    label: 'August',
    value: '08'
  },
  {
    label: 'September',
    value: '09'
  },
  {
    label: 'October',
    value: '10'
  },
  {
    label: 'November',
    value: '11'
  },
  {
    label: 'December',
    value: '12'
  }
];

export { months, years };
