// hooks
import { useQuery } from '@tanstack/react-query';
import apiRequest from '../../config/apiRequest';

const useReportingRequest = (startDate, endDate) => {
  const { isLoading, data, refetch } = useQuery(
    ['getTenants', startDate, endDate],
    () =>
      apiRequest({
        method: 'GET',
        endpoint: `org/reporting/devices?startDate=${startDate.format(
          'YYYY-MM-DD'
        )}&endDate=${endDate ? endDate.format('YYYY-MM-DD') : ''}`
      })
  );

  return {
    reportingDevices: data?.data || [],
    reportingIsLoading: isLoading,
    refetchReporting: refetch
  };
};

export default useReportingRequest;
