// hooks
import React from 'react';

import { years } from './helpers/index';

const YearSelector = ({ ...props }) => {
  return (
    <select name="year" className="select-month mr-4" {...props}>
      <option label="Select Year" />
      {years().map((year, index) => (
        <option key={index} value={year}>
          {year}
        </option>
      ))}
    </select>
  );
};

export default YearSelector;
