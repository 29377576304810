import React, { useState } from 'react';
import axios from 'axios';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { Box, Grid, makeStyles, Modal } from '@material-ui/core';
import AddNewMemberModal from '../modals/AddNewMemberModal';
import EditUserModal from '../modals/EditUserModal';
import { toAbsoluteUrl } from '_metronic/_helpers';
import { baseUrl } from 'app/config/baseUrl';
import useTenantPageRequest from '../../../../components/hooks/useTenantPageRequest';

export default function UsersTable({ tenantId, getTenantId, tenant }) {
  const [openedTenant, setOpenedTenant] = useState(null);
  const [openEdit, setOpenEdit] = useState(null);
  const [userId, setUserId] = useState(null);
  const [user, setuser] = useState();

  const {
    tenantUsers,
    tenantUsersIsLoading,
    refetchTenantUsers
  } = useTenantPageRequest({ tenantId, getTenantId: getTenantId() });

  function resetPassword(id) {
    axios
      .post(`${baseUrl}/org/tenant/${id}/users/reset-password`)
      .then(response => {
        console.log(response.status);
      })
      .catch(e => {
        console.log(e);
      });
  }

  /* begin::Modal */
  function getModalStyle() {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-${50}%, -${50}%)`
    };
  }

  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 586,
      borderRadius: 12,
      backgroundColor: '#F2F4F5',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    paper2: {
      position: 'absolute',
      width: 380,
      // height: "95%",
      borderRadius: 12,
      backgroundColor: '#F2F4F5',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    }
  }));

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = item => {
    setOpenedTenant(item);
  };

  const handleClose = () => {
    setOpenedTenant(null);
  };

  // delete user start
  function deleteBody(item) {
    if (item === null) {
      return null;
    }

    return (
      <div style={modalStyle} className={classes.paper}>
        <span className="closemodal" onClick={handleClose}>
          <SVG
            className="closemodal"
            src={toAbsoluteUrl('/media/qwerty/closemodal.svg')}
          />
        </span>
        <div className="deletetenantcontainer bg-white">
          <div style={{ marginTop: '30px', width: '100%' }}>
            <Box>
              <p className="delete-question">
                <FormattedMessage id="DELETE.TENANT.QUESTION.1" />
                <br />
                <FormattedMessage id="DELETE.USER.QUESTION" />
              </p>
              <hr />
            </Box>
            <Box>
              <Grid container spacing={2} className="deletebtngrid">
                <Grid item>
                  <button onClick={handleClose} className="delete-no">
                    <FormattedMessage id="NO" />
                  </button>
                </Grid>
                <Grid item>
                  <button
                    className="delete-yes"
                    onClick={() => {
                      onDelete(openedTenant.id);
                      handleClose();
                    }}
                  >
                    <FormattedMessage id="YES" />
                  </button>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    );
  }
  /* end::Modal */

  const onDelete = item => {
    axios
      .delete(`${baseUrl}/org/tenant/${getTenantId()}/users/${item}`)
      .then(() => {
        refetchTenantUsers();
      })
      .catch(e => {
        console.log(e.response);
      });
  };

  //delete user end

  //User Info Modal

  const handleOpenEdit = item => {
    setOpenEdit(item);
    setuser(item);
  };

  const handleCloseEdit = () => {
    setOpenEdit(null);
    setUserId(null);
    refetchTenantUsers();
  };

  function editBody(item) {
    if (item === null) {
      return null;
    }
    return (
      <>
        <div style={modalStyle} className={classes.paper2}>
          <span
            style={{ marginBottom: 30 }}
            className="closemodal"
            onClick={handleCloseEdit}
          >
            <SVG
              className="closemodal"
              src={toAbsoluteUrl('/media/qwerty/closemodal.svg')}
            />
          </span>
          <div className="editusermodalcontainer">
            <span>
              <SVG
                className="user-img"
                src={toAbsoluteUrl('/media/svg/Userimg.svg')}
              />
            </span>
            <h3 className="user-title">
              {' '}
              {openEdit?.firstName + ' ' + openEdit?.lastName}
            </h3>
            <p className="mb-15 user-subtitle">{openEdit?.username}</p>
            <div className="user-info-details">
              <div className="first-col row">
                <p className="user-det-title">{tenant}</p>
                <p className="user-det-p">
                  <FormattedMessage id="TENANT" />
                </p>
                <p className="user-det-title">{openEdit?.language}</p>
                <p className="user-det-title">{openEdit?.phone}</p>
                <p className="user-det-p">
                  <FormattedMessage id="PHONE" />
                </p>
              </div>
              <div className="row second-col">
                <p className="user-det-title">{openEdit?.userType}</p>
                <p className="user-det-p">
                  <FormattedMessage id="TYPE" />
                </p>
                <p className="user-det-title">
                  <FormattedMessage id="ACTIVE" />
                </p>
                <p className="user-det-p">
                  <FormattedMessage id="STATUS" />
                </p>
              </div>
            </div>
            <button className="user-reset-pass-btn">
              <span
                onClick={() => resetPassword(getTenantId())}
                className="user-reset-pass-text"
              >
                <FormattedMessage id="RESET.PASSWORD" />
              </span>
            </button>
            <button
              style={{ marginTop: '5%' }}
              type="button"
              className="user-edit-btn"
              onClick={() => {
                setOpenEdit(null);
                setUserId(openEdit?.id);
              }}
            >
              <p className="user-edit-text">
                <FormattedMessage id="EDIT" />
              </p>
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="card card-custom">
      <div className="card-header border-0 py-5">
        <div>
          <h3 className="card-title tabletitle align-items-start flex-column">
            <FormattedMessage id="USERS" />
          </h3>
          <p className="users-table-tenant-name">{tenant}</p>
        </div>
        <div className="card-toolbar">
          {!tenantId && <AddNewMemberModal onClose={refetchTenantUsers} />}
        </div>
      </div>
      <div className="card-body py-0">
        <div className="table-responsive">
          <table
            className="table table-head-custom tenants-users-table table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr>
                <th className="width-158">
                  <FormattedMessage id="USER.DETAILS" />
                </th>
                <th className="p-0 width-50">
                  <FormattedMessage id="USERNAME" />
                </th>
                <th className="p-0 width-50">
                  <FormattedMessage id="STATUS" />
                </th>
                <th className="p-0 width-120" />
              </tr>
            </thead>
            <tbody>
              {!tenantUsersIsLoading &&
                tenantUsers.length > 0 &&
                tenantUsers.map((user, index) => (
                  <tr key={index}>
                    <td>
                      <div className="symbol symbol-50 symbol-light mt-1">
                        <span className="symbol-label">
                          <SVG
                            src={toAbsoluteUrl(
                              '/media/svg/avatars/001-boy.svg'
                            )}
                            className="h-75 align-self-end"
                          />
                        </span>
                      </div>
                      <div className="table-vertical-users">
                        <span
                          onClick={() => {
                            handleOpenEdit(user);
                          }}
                          className="margin-left-12 text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer"
                        >
                          {user.firstName + ' ' + user.lastName}
                        </span>
                      </div>
                    </td>
                    <td className="pl-0">
                      <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                        {user.username}
                      </span>
                    </td>
                    <td>
                      <span className="tenant-active">
                        <FormattedMessage id="ACTIVE" />
                      </span>
                    </td>
                    <td className="text-right">
                      {tenantId ? null : (
                        <>
                          <div
                            className="btn btn-icon btn-bckg hoverr btn-sm mx-3"
                            onClick={() => handleOpenEdit(user)}
                          >
                            <span>
                              <SVG
                                src={toAbsoluteUrl(
                                  '/media/svg/icons/Communication/Write-2.svg'
                                )}
                              />
                            </span>
                          </div>
                          <div
                            className="btn btn-icon btn-bckg hoverr btn-sm mx-3"
                            onClick={() => handleOpen(user)}
                          >
                            <span>
                              <SVG
                                src={toAbsoluteUrl(
                                  '/media/svg/icons/General/Trash-2.svg'
                                )}
                              />
                            </span>
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        open={openedTenant !== null}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {deleteBody(openedTenant !== null)}
      </Modal>

      <Modal
        open={openEdit !== null}
        onClose={handleCloseEdit}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {editBody(openEdit !== null)}
      </Modal>
      <EditUserModal
        userId={userId}
        user={user}
        handleCloseEdit={handleCloseEdit}
      />
    </div>
  );
}
