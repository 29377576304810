// hooks
import React, { useMemo, useState } from 'react';

// libs
import { Link, Switch, Redirect } from 'react-router-dom';
import { toAbsoluteUrl } from '_metronic/_helpers';

// components
import LanguageSelectorDropdown from '../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown';
import Login from './Login';
import ForgotPasswordStep1 from '../../../pages/passwordRecovery/ForgotPasswordStep1';
import ForgotPasswordStep2 from '../../../pages/passwordRecovery/ForgotPasswordStep2';
import { ContentRoute } from '../../../../_metronic/layout';

// helpers
import { extractSubdomain } from '../../../helpers/ExtractSubdomain';

const AuthPage = () => {
  const [username, setUsername] = useState('');

  const renderCoverImage = useMemo(() => {
    const subDomain = extractSubdomain();

    if (subDomain === 'axistowers') {
      return 'Axis-photo.webp';
    } else if (subDomain === 'tbilisicity') {
      return 'paragraph.webp';
    }
    return '';
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden col-lg-6">
            <LanguageSelectorDropdown />
            <div className="d-flex align-items-center justify-content-center main-page-logo">
              <Link to="/auth">
                <img
                  className="auth-qwerty-logo"
                  alt="Logo"
                  src={toAbsoluteUrl('/media/qwerty/logo.svg')}
                />
              </Link>
            </div>
            <div className="d-flex justify-content-center">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  render={props => (
                    <ForgotPasswordStep2
                      {...props}
                      username={username}
                      setUsername={setUsername}
                    />
                  )}
                  path="/auth/forgot-password/otp"
                />
                <ContentRoute
                  render={props => (
                    <ForgotPasswordStep1
                      {...props}
                      username={username}
                      setUsername={setUsername}
                    />
                  )}
                  path="/auth/forgot-password"
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
          <div
            className="d-flex flex-row-auto bgi-size-auto bgi-no-repeat col-lg-6"
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                `/media/qwerty/${renderCoverImage}`
              )})`,
              backgroundSize: 'cover'
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AuthPage;
