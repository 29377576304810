// hooks
import React, { useEffect, useState, useRef } from 'react';
import { Fragment } from 'react';

// libs
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// config
import { tenantIcons } from 'app/config/tenantIcons';

// custom hooks
import useAllTenantsRequest from 'app/components/hooks/useAllTenantsRequest';

// helpers
import { renderTenantIconByDomain } from 'app/helpers/renderTenantIconByDomain';
import { numbersWithSpaces } from 'app/helpers/numberWithSpaces';

const BillingTenants = ({ className }) => {
  const initialData = useRef([]);
  const [tenantsList, setTenantsList] = useState([]);
  const [search, setSearch] = useState('');
  const { tenants, tenantsIsLoading } = useAllTenantsRequest();

  useEffect(() => {
    const getTenants = () => {
      if (tenants && !tenantsIsLoading) {
        const filtered = tenants.filter(
          item => item.deactivation_date === null && item.id !== 118
        );
        const sortTenants = filtered.sort((a, b) =>
          a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
        );
        setTenantsList(sortTenants);
        initialData.current = sortTenants;
      }
    };
    getTenants();
  }, [tenants, tenantsIsLoading]);

  useEffect(() => {
    if (search.length > 0) {
      const filteredItems = tenantsList.filter(item => {
        let matches = false;
        for (let key in item) {
          if (
            String(item[key])
              .toLowerCase()
              .indexOf(search) > -1
          ) {
            matches = true;
            break;
          }
        }
        return matches;
      });
      setTenantsList(filteredItems);
    } else {
      setTenantsList(initialData.current);
    }
  }, [search]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-title tabletitle flex-1">
            <FormattedMessage id="TENANTS" />
          </span>
        </h3>
        <Link to="/invoices">
          <button className="create-invoice">
            <FormattedMessage id="CREATE.INVOICE" />
          </button>
        </Link>
      </div>
      <div className="tenant-search">
        <form className="form-inline">
          <input
            value={search}
            onChange={e => setSearch(e.target.value)}
            className="form-control reporting-search mr-sm-2 tenant-search-input"
            type="text"
            placeholder="Search"
          />
        </form>
      </div>
      <div className="card-body py-0">
        <div className="table-responsive">
          <table className="table table-head-custom tenants-table table-vertical-center">
            <thead className="thead-tenant">
              <tr>
                <th className="thead-p width-50perc">
                  <FormattedMessage id="NAME" />
                </th>
                <th className="thead-p width-50">
                  <span>
                    <FormattedMessage id="HEATING.TARIFF" />
                  </span>
                </th>
                <th className="thead-p width-50">
                  <span>
                    <FormattedMessage id="COOLING.TARIFF" />
                  </span>
                </th>
                <th className="thead-p width-100">
                  <FormattedMessage id="HEATING" />
                </th>
                <th className="thead-p width-100">
                  <FormattedMessage id="COOLING" />
                </th>
              </tr>
            </thead>
            <tbody>
              {tenantsList &&
                tenantsList.map(item => {
                  const tenantIcon =
                    tenantIcons.find(tenant => tenant.id === item.id)?.icon ||
                    renderTenantIconByDomain();

                  return (
                    <Fragment key={item.id}>
                      <tr>
                        <td className="pl-0 border-none">
                          <div className="d-flex align-items-center">
                            {item && (
                              <div
                                className={`tenant-icon-div ${
                                  item.id !== 89 ? 'icon-border' : ''
                                }`}
                              >
                                <img
                                  className="tenant-icon"
                                  src={tenantIcon}
                                  alt="tenant-icon"
                                />
                              </div>
                            )}
                            <div className="table-vertical mt-4">
                              <div className="ml-4">
                                <Link to={`/tenant/${item.id}`}>
                                  <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                    {item.name}
                                  </span>
                                </Link>
                              </div>
                              <span className="text-muted font-weight-bold text-muted d-block margin-left-12">
                                {item.address}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="border-none">
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {/* {(item.billingInfo.heatingInfo.priceInfo.oneGjPrice).toFixed(2)}  */}
                            0(₾)
                          </span>
                          <span className="text-muted font-weight-bold">
                            Tariff {item.tariff_heat.name}
                          </span>
                        </td>
                        <td className="border-none">
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {/* {(item.billingInfo.coolingInfo.priceInfo.oneGjPrice).toFixed(2)}  */}
                            0 (₾)
                          </span>
                          <span className="text-muted font-weight-bold">
                            Tariff {item.tariff_cold.name}
                          </span>
                        </td>
                        <td className="border-none">
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {/* {numbersWithSpaces((item.billingInfo.heatingInfo.priceInfo.totalPrice).toFixed(2))}
                             */}
                            0 (₾)
                          </span>
                          <span className="text-muted font-weight-bold">
                            {item.billingInfo &&
                              numbersWithSpaces(
                                item.billingInfo?.heatingInfo?.deviceInfo?.totalEnergyInMonth?.toFixed(
                                  2
                                )
                              )}{' '}
                            GJ
                          </span>
                        </td>
                        <td className=" border-none">
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {/* {numbersWithSpaces((item.billingInfo.coolingInfo.priceInfo.totalPrice).toFixed(2))} */}
                            0 (₾)
                          </span>
                          <span className="text-muted font-weight-bold">
                            {item.billingInfo &&
                              numbersWithSpaces(
                                item.billingInfo?.coolingInfo?.deviceInfo?.totalEnergyInMonth?.toFixed(
                                  2
                                )
                              )}{' '}
                            GJ
                          </span>
                        </td>
                      </tr>
                      <tr
                        className={`collapse border-round multi-collapse${item.id}`}
                        id={`multiCollapseExample${item.id}`}
                      >
                        <td className="border-none td-title">
                          <FormattedMessage id="DEVICE.ID" />
                        </td>
                        <td className="border-none td-title" />
                        <td className="border-none td-title" />
                        <td className="border-none td-title">
                          <FormattedMessage id="TYPE" />
                        </td>
                        <td className="border-none td-title">
                          <FormattedMessage id="CONSUMPTION" />
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BillingTenants;
