import React from 'react';
import { Helmet } from 'react-helmet';

// components
import OrganizationDetails from './components/OrganizationDetails';
import OrgDevicesTable from './components/OrgDevicesTable';
import TenantsTable from './components/TenantsTable';

const MainPage = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className="row">
        <div className=" col-md-4 col-sm-5 col-lg-3 margin-top-25 col-xxl-3 ">
          <OrganizationDetails />
        </div>
        <div className="col-md-8 col-sm-7 col-lg-9 col-xxl-9 margin-top-25">
          <TenantsTable className="card- gutter-b" />
          <div className="margin-top-25">
            <OrgDevicesTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;
