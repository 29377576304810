/* eslint-disable no-restricted-imports */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import SVG from 'react-inlinesvg';
import { Button } from '@material-ui/core';
import { toAbsoluteUrl } from '_metronic/_helpers';
import AddUserForm from '../forms/AddUserForm';

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${50}%, -${50}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 586,
    maxHeight: 736,
    borderRadius: 12,
    backgroundColor: '#F2F4F5',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none'
  }
}));

export default function AddUsersToTenant(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <span className="closemodal" onClick={handleClose}>
        <SVG
          className="closemodal"
          src={toAbsoluteUrl('/media/qwerty/closemodal.svg')}
        />
      </span>
      <AddUserForm id={props.tenantId} />
    </div>
  );

  return (
    <div>
      <Button
        type="button"
        onClick={handleOpen}
        style={{
          background: '#8950FC',
          color: 'white',
          width: '155px',
          height: '40px'
        }}
        className="btn font-weight-bolder font-size-sm"
      >
        {' '}
        Add users to tenant
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
