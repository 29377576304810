// hooks
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

// components
import ExpenditureDetails from './components/expenditureDetails/ExpenditureDetails';
import ExpenditureHistory from './components/ExpenditureHistory';
import TenantDetails from './components/TenantDetails';
import TenantsDevicesTable from './components/tables/TenantDevicesTable';
import UsersTable from './components/tables/UsersTable';
import { SplashScreen } from '../../../_metronic/_partials/controls';

// custom hooks
import useTenantPageRequest from '../../components/hooks/useTenantPageRequest';
import DeviceFlowTable from './components/tables/DeviceFlowTable';

const TenantPage = () => {
  const [heatFixed, setHeatFixed] = useState(0);
  const [coolFixed, setCoolFixed] = useState(0);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  const { tenantId } = useSelector(({ auth }) => {
    return {
      tenantId: auth.user ? auth.user.tenantId : null
    };
  }, shallowEqual);

  const getTenantId = () => {
    if (!tenantId) {
      return params.id;
    } else {
      return tenantId;
    }
  };

  const {
    tenantDevicesList,
    tenantDevicesListIsLoading,
    tenant
  } = useTenantPageRequest({ tenantId, getTenantId: getTenantId() });

  useEffect(() => {
    if (!tenantDevicesListIsLoading) {
      setLoading(false);
    }
  }, [tenantDevicesListIsLoading]);

  const setTariff = () => {
    if (tenant.tariff_cold === undefined || tenant.tariff_cold === null) {
      return null;
    } else {
      setCoolFixed(tenant.tariff_cold.fixed);
    }
    if (tenant.tariff_heat === undefined || tenant.tariff_heat === null) {
      return null;
    } else {
      setHeatFixed(tenant.tariff_heat.fixed);
    }
  };

  useEffect(() => {
    setTariff();
  }, [tenant]);

  return (
    <>
      <Helmet>
        <title>{tenant?.name || ''}</title>
      </Helmet>
      {loading ? (
        <SplashScreen />
      ) : (
        <div className="row">
          <div className="col-xl-4 col-lg-5 col-md-6 col-xs-12 margin-top-25">
            <TenantDetails
              tenantId={tenantId}
              getTenantId={getTenantId}
              tenant={tenant}
              className="gutter-b"
            />
            <div className="margin-top-25">
              <ExpenditureDetails
                className="gutter-b"
                devices={tenantDevicesList}
              />
            </div>
          </div>
          <div className="col-xl-8 col-md-6 col-lg-7 col-xs-12 margin-top-25">
            <ExpenditureHistory
              heatFixed={heatFixed}
              coolFixed={coolFixed}
              tenantId={tenantId}
              getTenantId={getTenantId}
              className="gutter-b"
            />
            <div className="margin-top-25">
              <TenantsDevicesTable
                devices={tenantDevicesList}
                className="gutter-b"
              />
            </div>
            <div className="margin-top-25">
              <UsersTable
                tenantId={tenantId}
                getTenantId={getTenantId}
                tenant={tenant?.name || ''}
              />
            </div>
            {getTenantId() === '154' && (
              <div className="margin-top-25">
                <DeviceFlowTable tenantId={tenantId || getTenantId()} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TenantPage;
