// hooks
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { toAbsoluteUrl } from '_metronic/_helpers';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import axios from 'axios';
import { numbersWithSpaces } from 'app/helpers/numberWithSpaces';

// components
import SVG from 'react-inlinesvg';

// styles
import './Reporting.scss';

// config
import { baseUrl } from 'app/config/baseUrl';
import useReportingRequest from '../../components/hooks/useReportingRequest';

export default function Reporting() {
  const initialData = useRef([]);
  const [reportingData, setReportingData] = useState([]);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const [focusedInput, setFocusedInput] = useState();

  const { reportingDevices, reportingIsLoading } = useReportingRequest(
    startDate,
    endDate
  );

  useEffect(() => {
    if (reportingDevices && !reportingIsLoading) {
      let activeDevices = reportingDevices.filter(
        item => item.Tenant.id !== null
      );
      let sortDevices = activeDevices.sort((a, b) =>
        a.Tenant.name !== b.Tenant.name
          ? a.Tenant.name < b.Tenant.name
            ? -1
            : 1
          : 0
      );
      setReportingData(sortDevices);
      initialData.current = sortDevices;
    }
  }, [reportingDevices, reportingIsLoading]);

  useEffect(() => {
    if (search.length > 0) {
      const filteredItems = reportingData.filter(item => {
        let matches = false;
        for (let key in item) {
          if (
            String(item[key])
              .toLowerCase()
              .indexOf(search) > -1
          ) {
            matches = true;
            break;
          }
        }
        return matches;
      });
      setReportingData(filteredItems);
    } else {
      setReportingData(initialData.current);
    }
  }, [search]);

  async function downloadAllReporting() {
    await axios({
      url: `${baseUrl}/org/reporting/devices/export?token=&fbclid=IwAR0JVlt1JxFeYQUyh0l-Smf3gBmcwdjnhqRWGc7Uz1JpMFp6PhclA3Dzot8jwt&startDate=${startDate.format(
        'YYYY-MM-DD'
      )}&endDate=${endDate ? endDate.format('YYYY-MM-DD') : ''}`,
      method: 'GET',
      responseType: 'blob'
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.log(error.response);
      });
  }

  async function downloadDeviceReport(serialNumber) {
    let devices = await axios.get(`${baseUrl}/org/reporting/devices`);
    devices = devices.data;
    const device = devices.find(item => item.serialNumber === serialNumber);

    if (device) {
      const deviceId = device.id;

      axios({
        url: `${baseUrl}/org/reporting/devices/${deviceId}/export?startDate=${startDate.format(
          'YYYY-MM-DD'
        )}&endDate=${endDate ? endDate.format('YYYY-MM-DD') : ''}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `report-${deviceId}.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  }

  return (
    <div className="card margin-top-25 reportingContainer card-custom">
      <Helmet>
        <title>Reporting</title>
      </Helmet>
      <>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="tabletitle">
              <FormattedMessage id="REPORTING" />
            </span>
          </h3>
        </div>
        <div className="card-body py-0">
          <form className="form-inline col-xs-6">
            <input
              value={search}
              onChange={e => setSearch(e.target.value)}
              className="form-control reporting-search mr-sm-2"
              type="text"
              placeholder="Search By Device ID Number"
            />
            <div className="search-month-download-reporting d-flex">
              <div className="position-relative">
                <DateRangePicker
                  displayFormat={() => 'DD MMM.YYYY'}
                  startDate={startDate}
                  isOutsideRange={date => date <= moment('2022-03-01')}
                  startDateId="start-date"
                  endDate={endDate}
                  endDateId="end-date"
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                />
                <SVG
                  className="calendar-icon"
                  src={toAbsoluteUrl('/media/svg/calendar.svg')}
                />
              </div>
              <SVG
                className="down-reporting"
                onClick={downloadAllReporting}
                src={toAbsoluteUrl('/media/svg/downloadtext.svg')}
              />
            </div>
          </form>
          <div className="table-responsive">
            <table
              className="table table-head-custom reporting-table table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="width-104">
                    <FormattedMessage id="DEVICE.ID" />
                  </th>
                  <th className="width-158">
                    <FormattedMessage id="DEVICE.NAME" />
                  </th>
                  <th className="width-158">
                    <FormattedMessage id="TENANTS" />
                  </th>
                  <th className="width-104">
                    <FormattedMessage id="TYPE" />
                  </th>
                  <th className="width-104">
                    <span>GJ</span>
                  </th>
                  <th className="width-104">
                    <FormattedMessage id="TARIFF" />
                  </th>
                  <th className="width-104">
                    <FormattedMessage id="STATUS" />
                  </th>
                  <th className="width-104 text-center">
                    <FormattedMessage id="DOWNLOAD.REPORT" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {reportingData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.serialNumber}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.deviceName}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.Tenant.name}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.deviceType}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.billingInfo?.deviceInfo && item.id === 70
                          ? numbersWithSpaces(
                              item?.billingInfo?.deviceInfo?.totalEnergyDiff?.toFixed(
                                2
                              )
                            )
                          : numbersWithSpaces(
                              item?.billingInfo?.deviceInfo?.totalEnergy?.toFixed(
                                2
                              )
                            )}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        Tariff {item.billingInfo?.tariff.name}
                      </span>
                    </td>
                    <td>
                      <span className="tenant-active">
                        <FormattedMessage id="ACTIVE" />
                      </span>
                    </td>
                    <td className="text-center">
                      <span
                        className="symbol-label"
                        onClick={() => {
                          downloadDeviceReport(item.serialNumber);
                        }}
                      >
                        <SVG
                          src={toAbsoluteUrl('/media/svg/download.svg')}
                          className="align-self-end cursor-pointer"
                        />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </div>
  );
}
