// hooks
import React, { useState, useEffect, useCallback } from 'react';

// components
import YearSelector from 'app/components/selector/YearSelector';
import MonthSelector from 'app/components/selector/MonthSelector';

// utils
import moment from 'moment';
import { numbersWithSpaces } from 'app/helpers/numberWithSpaces';

// custom hooks
import useDevicesFlowRequest from '../../../../components/hooks/useDevicesFlowRequest';

const columns = ['Device ID', 'Date', 'FLOW'];

// 1) Check tenantId === 154; +
// 2) Check Device with ID === 111; +
// 3) Get Flow Of Device; +
// 4) Set Up Table With Columns; +
// 5) Do UI Of This Device's Water Flow; +

const DeviceFlowTable = ({ tenantId }) => {
  const [currentMonthData, setCurrentMonthData] = useState([]);
  const [month, setMonth] = useState(moment().format('YYYY-MM'));
  const [year, setYear] = useState(moment().format('YYYY'));

  const { devicesFlow, isLoading } = useDevicesFlowRequest(tenantId);

  const getFilteredDeviceFlow = useCallback(() => {
    const res = devicesFlow.filter(
      device =>
        device.serial_number === '0128549' &&
        moment(device.log_date).format('YYYY-MM') === month
    );
    const sortedData = res.sort((a, b) =>
      a.log_date !== b.log_date ? (a.log_date < b.log_date ? -1 : 1) : 0
    );
    setCurrentMonthData(sortedData);
  }, [month]);

  useEffect(() => {
    if (!isLoading && devicesFlow?.length > 0) {
      getFilteredDeviceFlow();
    }
  }, [isLoading, devicesFlow, month]);

  const sum = currentMonthData
    .reduce(
      (partialSum, a) => partialSum + a?.original_all_day_net_total_flow,
      0
    )
    .toFixed(2);

  return (
    <div className="card card-custom">
      <div className="card-header border-0 py-5">
        <div className="d-flex justify-content-between w-100 align-items-center">
          <h3 className="card-title tabletitle align-items-start flex-column">
            Device Water Flow
          </h3>
          <div className="selector-div">
            <YearSelector
              onChange={e => {
                setYear(e.target.value);
              }}
              value={year}
            />
            <MonthSelector
              onChange={e => {
                setMonth(e.target.value);
              }}
              value={month}
              year={year}
            />
          </div>
        </div>
      </div>
      <div className="card-body w-full py-0">
        <table
          className="table table-head-custom tenants-devices-table table-vertical-center"
          id="kt_advance_table_widget_1"
        >
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={index} className="width-158 thead-p">
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentMonthData?.map((device, index) => (
              <tr key={index}>
                <td>
                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                    {device.serial_number}
                  </span>
                </td>
                <td>
                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                    {moment(device.log_date).format('DD-MM-YYYY')}
                  </span>
                </td>
                <td>
                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                    {numbersWithSpaces(
                      device.original_all_day_net_total_flow.toFixed(2)
                    )}
                  </span>
                </td>
              </tr>
            ))}
            <tr>
              <td />
              <td />
              <td>
                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                  Total Flow: {numbersWithSpaces(sum)}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DeviceFlowTable;
