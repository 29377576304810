import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '_metronic/_helpers';

export default function Toggle() {
  const [toggle, setToggle] = useState(false);

  const toggler = () => {
    toggle ? setToggle(false) : setToggle(true);
  };

  return (
    <div onClick={() => toggler()}>
      {toggle ? (
        <SVG src={toAbsoluteUrl('/media/svg/Sufflix2.svg')} />
      ) : (
        <SVG src={toAbsoluteUrl('/media/svg/Sufflix.svg')} />
      )}
    </div>
  );
}
