/* eslint-disable no-restricted-imports */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '_metronic/_helpers';
import AddUserForm from '../../../../components/forms/AddUserForm';
import { FormattedMessage } from 'react-intl';

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${50}%, -${50}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 586,
    borderRadius: 12,
    backgroundColor: '#F2F4F5',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none'
  }
}));

export default function AddNewMemberModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <span className="closemodal" onClick={handleClose}>
        <SVG
          className="closemodal"
          src={toAbsoluteUrl('/media/qwerty/closemodal.svg')}
        />
      </span>
      <AddUserForm />
    </div>
  );

  return (
    <div>
      <button type="button" onClick={handleOpen} className="add-tenant-btn">
        <FormattedMessage id="ADD.USER" />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
