// hooks
import React, { useMemo } from 'react';

// helpers
import { months } from './helpers/index';

const MonthSelector = ({ year, ...props }) => {
  const monthsList = useMemo(() => {
    if (year === '2022') {
      return months.slice(3, 12);
    } else {
      return months;
    }
  }, [year]);

  return (
    <select name="months" className="select-month" {...props}>
      <option label="Select Month" />
      {monthsList.map((month, index) => (
        <option key={index} value={year + `-${month.value}`}>
          {month.label}
        </option>
      ))}
    </select>
  );
};

export default MonthSelector;
