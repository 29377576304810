// hooks
import React, { useContext, useEffect, useState } from 'react';

// libs
import axios from 'axios';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

// styles
import '../Billing.scss';

// components
import SVG from 'react-inlinesvg';
import { numbersWithSpaces } from 'app/helpers/numberWithSpaces';
import AlertModal from 'app/components/modals/AlertModal';
import YearSelector from 'app/components/selector/YearSelector';
import MonthSelector from 'app/components/selector/MonthSelector';
import BillingContext from 'app/components/context/BillingContext';

// helpers
import { toAbsoluteUrl } from '_metronic/_helpers';

// config
import { baseUrl } from 'app/config/baseUrl';
import { currentDate } from 'app/config/getCurrentDate';
import { extractSubdomain } from 'app/helpers/ExtractSubdomain';

const HeatingCalculation = ({ tariffsData }) => {
  const { heatingDate, setHeatingDate } = useContext(BillingContext);
  const [gasConsumption, setGasConsumption] = useState('');
  const [gasTariff, setGasTariff] = useState('');
  const [eComsumption, setEComsumption] = useState('');
  const [eTariff, setETariff] = useState('');
  const [additionalCost, setAdditionalCost] = useState('');
  const [month, setMonth] = useState(moment(heatingDate).format('YYYY-MM'));
  const [year, setYear] = useState(moment(heatingDate).format('YYYY'));
  const [gjInfo, setGjInfo] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [oneGjPrice, setOneGjPrice] = useState(0);
  const [dieselTariff, setDieselTariff] = useState(0);
  const [dieselConsumption, setDieselConsumption] = useState(0);
  const [alert, setAlert] = useState('');

  const subDomain = extractSubdomain();

  const isTbilisiCity = subDomain === 'tbilisicity';

  const axisAxiosBody = {
    gasConsumption,
    gasTariff,
    eComsumption,
    eTariff,
    additionalCost,
    month
  };

  const tbilisicityAxiosBody = {
    gasConsumption,
    gasTariff,
    eComsumption,
    eTariff,
    additionalCost,
    month,
    dieselTariff,
    dieselConsumption
  };

  //dieselTariff
  //dieselConsumption

  useEffect(() => {
    async function fetchOldCalculations() {
      await axios
        .get(`${baseUrl}/billing/invoice/latest`, {
          params: { month, type: 'HEATING' }
        })
        .then(response => {
          setTotalAmount(response.data.totalPrice);
          setOneGjPrice(response.data.oneGjPrice);
          setGasConsumption(response.data.gasConsumption);
          setGasTariff(response.data.gasTariff);
          setEComsumption(response.data.eComsumption);
          setETariff(response.data.eTariff);
          setAdditionalCost(response.data.additionalCost);
          setHeatingDate(month);

          if (isTbilisiCity) {
            setDieselTariff(response.data?.dieselTariff);
            setDieselConsumption(response.data?.dieselConsumption);
          }

          axios
            .post(`${baseUrl}/billing/calculate/all-tariffs/heating`, {
              gasConsumption: response.data.gasConsumption,
              gasTariff: response.data.gasTariff,
              eComsumption: response.data.eComsumption,
              eTariff: response.data.eTariff,
              additionalCost: response.data.additionalCost,
              dieselTariff: response.data?.dieselTariff,
              dieselConsumption: response.data?.dieselConsumption,
              month
            })
            .then(response => {
              setGjInfo(response.data);
            })
            .catch(e => {
              setAlert(e.response.data.message);
              // console.log(e.response);
            });
        })
        .catch(e => {
          if (e.response.status === 404) {
            setTotalAmount(0);
            setOneGjPrice(0);
            setGasConsumption(0);
            setGasTariff(0);
            setEComsumption(0);
            setETariff(0);
            setAdditionalCost(0);
            setGjInfo(0);
            setDieselTariff(0);
            setDieselConsumption(0);
          }
        });
    }

    if (month && year) {
      fetchOldCalculations();
    }
  }, [month, year]);

  async function onSubmit(event) {
    event.preventDefault();
    await axios
      .post(
        `${baseUrl}/billing/calculate/all-tariffs/heating`,
        isTbilisiCity ? tbilisicityAxiosBody : axisAxiosBody
      )
      .then(response => {
        setTotalAmount(response.data[0].priceInfo.totalPrice);
        setOneGjPrice(response.data[0].priceInfo.oneGjPrice);
        setGjInfo(response.data);
        axios
          .post(
            `${baseUrl}/billing/create/heating`,
            isTbilisiCity ? tbilisicityAxiosBody : axisAxiosBody
          )
          .then(() => {})
          .catch(e => {
            setAlert(e.response.data.message);
          });
      })
      .catch(e => {
        setAlert(e.response.data.message);
      });
  }

  return (
    <>
      <div className={`card card-custom`}>
        {alert && <AlertModal alert={alert} setAlert={setAlert} />}
        <div className="card-body billing-calculation-container pt-0">
          <div className="d-flex flex-wrap align-items-center mt-10">
            <div className="symbol symbol-45 symbol-light mr-5">
              <span>
                <SVG
                  className="h-50 align-self-center"
                  src={toAbsoluteUrl('/media/svg/billing/heatbilling.svg')}
                />
              </span>
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <label className="billingorange">
                <FormattedMessage id="HEATING.CALCULATION" />
              </label>
            </div>
            <div className="selector-div">
              <YearSelector
                onChange={e => {
                  setYear(e.target.value);
                }}
                value={year}
              />
              <MonthSelector
                onChange={e => {
                  setMonth(e.target.value);
                }}
                value={month}
                year={year}
              />
            </div>
          </div>
          <div className="mt-10 d-flex justify-space-between">
            <div className="width-260">
              <form onSubmit={onSubmit} autoComplete="off">
                <label>
                  <FormattedMessage id="ELECTRICITY.TARIFF" />
                </label>
                <br />
                <input
                  value={eTariff}
                  onChange={e => setETariff(e.target.value)}
                  type="text"
                  className="calculationinput"
                />
                <br />
                <label>
                  <FormattedMessage id="ELECTRICITY.CONSUMPTION" />
                </label>
                <br />
                <input
                  value={eComsumption}
                  onChange={e => setEComsumption(e.target.value)}
                  type="text"
                  className="calculationinput"
                />
                <br />
                <label>
                  <FormattedMessage id="NATURAL.GAS.TARIFF" />
                </label>
                <br />
                <input
                  value={gasTariff}
                  onChange={e => setGasTariff(e.target.value)}
                  type="text"
                  className="calculationinput"
                />
                <br />
                <label>
                  <FormattedMessage id="NATURAL.GAS.CONSUMPTION" />( m
                  <sup>3</sup> )
                </label>
                <br />
                <input
                  value={gasConsumption}
                  onChange={e => setGasConsumption(e.target.value)}
                  type="text"
                  className="calculationinput"
                />
                <br />
                <label>
                  <FormattedMessage id="ADDITIONAL.COST" />
                </label>
                <br />
                <input
                  value={additionalCost}
                  onChange={e => setAdditionalCost(e.target.value)}
                  type="text"
                  className="calculationinput"
                />
                <br />
                {isTbilisiCity && (
                  <>
                    <label>
                      <FormattedMessage id="DIESEL.TARIFF" />
                    </label>
                    <br />
                    <input
                      value={dieselTariff}
                      onChange={e => setDieselTariff(e.target.value)}
                      type="text"
                      className="calculationinput"
                    />
                    <label>
                      <FormattedMessage id="DIESEL.CONSUMPTION" />
                    </label>
                    <br />
                    <input
                      value={dieselConsumption}
                      onChange={e => setDieselConsumption(e.target.value)}
                      type="text"
                      className="calculationinput"
                    />
                  </>
                )}
                <button type="submit" className="savebtn">
                  <FormattedMessage id="SAVE" />
                </button>
              </form>
            </div>
            <div>
              <div className="total-amount-div vertical-border">
                <p className="total-amount-text">
                  <FormattedMessage id="TOTAL.AMOUNT" />
                </p>
                <p className="total-amount">
                  {numbersWithSpaces(totalAmount.toFixed(2))} (₾)
                </p>
                {/* <hr className="horizontal-border" /> */}
                <div className="cost-div">
                  <p className="cost-gj">
                    <FormattedMessage id="1.GJ.COST" />
                  </p>
                  <p className="cost-num"> {oneGjPrice.toFixed(4)} ₾</p>
                  {tariffsData.map(e => {
                    let calculatedGj = gjInfo
                      ? gjInfo
                          .filter(item => item.tariff.id === e.id)
                          .map(item =>
                            item.priceInfo.oneGjTariffCost.toFixed(4)
                          )
                      : '0.00';
                    return (
                      <div key={e.id}>
                        <p className="cost-gj">
                          <FormattedMessage id="1.GJ.COST" />
                          (Tariff {e.name})
                        </p>
                        <p className="cost-num">{calculatedGj} ₾</p>
                      </div>
                    );
                  })}
                  <span className="cost-gj date">
                    <FormattedMessage id="DATE" />
                  </span>
                  <span className="cost-num">{currentDate()}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeatingCalculation;
