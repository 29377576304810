/* eslint-disable no-restricted-imports */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '_metronic/_helpers';
import EditUserForm from '../forms/EditUserForm';

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${50}%, -${50}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 656,
    borderRadius: 12,
    backgroundColor: '#F2F4F5',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none'
  }
}));

export default function EditUserModal({
  userId,
  user,
  handleCloseEdit,
  tenantId
}) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <div>
      <Modal
        open={!!userId}
        onClose={handleCloseEdit}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <span className="closemodal" onClick={handleCloseEdit}>
            <SVG
              className="closemodal"
              src={toAbsoluteUrl('/media/qwerty/closemodal.svg')}
            ></SVG>
          </span>
          <EditUserForm
            user={user}
            userId={userId}
            tenantId={tenantId}
            close={handleCloseEdit}
          />
        </div>
      </Modal>
    </div>
  );
}
