// hooks
import { useQuery } from '@tanstack/react-query';
import apiRequest from '../../config/apiRequest';

const useAllInvoicesRequest = (month) => {
  const { isLoading, data } = useQuery(['getAllInvoices', month], () =>
    apiRequest({
      method: 'GET',
      endpoint: `billing/invoice/list/tenants?month=${month}`
    })
  );

  return {
    invoices: data?.data || [],
    invoicesIsLoading: isLoading
  };
};

export default useAllInvoicesRequest;
