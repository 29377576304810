// helpers
import { extractSubdomain } from './ExtractSubdomain';

// icons
import Axis from '../assets/icons/axis.png';
import Paragraph from '../assets/icons/paragraph.jpg';
import TenantIcon from '../assets/icons/20.jpg';

export const renderTenantIconByDomain = () => {
  const subDomain = extractSubdomain();

  if (subDomain === 'axistowers') {
    return Axis;
  } else if (subDomain === 'tbilisicity') {
    return Paragraph;
  }
  return TenantIcon;
};
