const func = ({ devices, deviceType }) => {
  const temperature1 = devices
    .filter((item) => item.deviceType === deviceType)
    .map((item) => item.ActiveDevice?.temperature_1 || 0);

  const temperature2 = devices
    .filter((item) => item.deviceType === deviceType)
    .map((item) => item.ActiveDevice?.temperature_2 || 0);

  let tempRes1 = 0;
  let tempRes2 = 0;

  let diff = 0;

  if (temperature1.length > 0) {
    let sum = 0;
    for (let i = 0; i < temperature1.length; i++) {
      sum += temperature1[i];
    }
    tempRes1 = sum;
  }

  if (temperature2.length > 0) {
    let sum2 = 0;
    for (let i = 0; i < temperature2.length; i++) {
      sum2 += temperature2[i];
    }
    tempRes2 = sum2;
  }

  diff = (tempRes1 - tempRes2).toFixed(2);
  return diff < 0 ? diff * -1 : diff
}

const calculateTempDifference = ({ devices }) => {
  return {
    heatingTempDiff: func({ devices, deviceType: "HEATING" }) || 0,
    coolingTempDiff: func({ devices, deviceType: "COOLING" }) || 0,
  }
}

export {
  calculateTempDifference
}