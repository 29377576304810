// hooks
import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

// helpers
import { toAbsoluteUrl } from '_metronic/_helpers';

// components
import { numbersWithSpaces } from 'app/helpers/numberWithSpaces';

// custom hooks
import { useMyInfoRequest } from '../../../components/hooks/useMyInfoRequest';
import useSummeryRequest from '../../../components/hooks/useSummeryRequest';

// helpers
import { extractSubdomain } from '../../../helpers/ExtractSubdomain';

export default function OrganizationDetails() {
  // custom hooks
  const { summery, isLoading } = useSummeryRequest();
  const { myInfo } = useMyInfoRequest();

  const organizationInfo = useMemo(() => {
    return {
      name: myInfo?.Organization?.name?.split(' /')[0] || '',
      address: myInfo?.Organization?.address?.split(' /')[0] || ''
    };
  }, [myInfo]);

  const renderCoverImage = useMemo(() => {
    const subDomain = extractSubdomain();

    if (subDomain === 'axistowers') {
      return 'axis-small.webp';
    } else if (subDomain === 'tbilisicity') {
      return 'paragraph.webp';
    }
    return '';
  }, []);

  return (
    <div className="card card-custom bg-radial-gradient">
      <div className="card-body d-flex flex-column p-0">
        {renderCoverImage && (
          <img
            width="100%"
            height="100%"
            className="org-img"
            alt="Logo"
            src={toAbsoluteUrl(`/media/qwerty/${renderCoverImage}`)}
          />
        )}

        <h2 className="d-flex organization-title font-weight-bolder pt-4 justify-content-center">
          {organizationInfo.name}
        </h2>
        <span className="d-flex org-details justify-content-center text-black">
          {organizationInfo.address}
        </span>
        {!isLoading && summery && (
          <div className="card-spacer bg-white card-rounded flex-grow-1">
            <div className="row d-flex align-items-center">
              <SVG
                src={toAbsoluteUrl('/media/svg/cooling/heating.svg')}
                className="h-50"
              />
              <div className="col mr-0">
                <div className="poppins">
                  <FormattedMessage id="CENTRAL.NODE" />
                </div>
                <div className="font-size-sm text-muted font-weight-bold">
                  <FormattedMessage id="HEATING.CONSUMPTION" />
                </div>
              </div>
              <div className="details-nums">
                {numbersWithSpaces(summery.centralNode?.heating.toFixed(0))} GJ
              </div>
            </div>
            <div className="row py-6">
              <SVG
                src={toAbsoluteUrl('/media/svg/cooling/cooling.svg')}
                className="h-50"
              />
              <div className="col mr-0">
                <div className="poppins">
                  <FormattedMessage id="CENTRAL.NODE" />
                </div>
                <div className="font-size-sm text-muted font-weight-bold">
                  <FormattedMessage id="COOLING.CONSUMPTION" />
                </div>
              </div>
              <div className="details-nums">
                {numbersWithSpaces(summery.centralNode?.cooling.toFixed(0))} GJ
              </div>
            </div>
            <div className="row">
              <SVG
                src={toAbsoluteUrl('/media/svg/cooling/heating.svg')}
                className="h-50"
              />
              <div className="col mr-0">
                <div className="poppins">
                  <FormattedMessage id="ALL.TENANTS" />
                </div>
                <div className="font-size-sm text-muted font-weight-bold">
                  <FormattedMessage id="HEATING.CONSUMPTION" />
                </div>
              </div>
              <div className="details-nums">
                {numbersWithSpaces(
                  summery.allTenants?.heating < 0
                    ? '0'
                    : summery.allTenants?.heating.toFixed(0)
                )}{' '}
                GJ
              </div>
            </div>
            <div className="row py-6">
              <SVG
                src={toAbsoluteUrl('/media/svg/cooling/cooling.svg')}
                className="h-50"
              />
              <div className="col mr-0">
                <div className="poppins">
                  <FormattedMessage id="ALL.TENANTS" />
                </div>
                <div className="font-size-sm text-muted font-weight-bold">
                  <FormattedMessage id="COOLING.CONSUMPTION" />
                </div>
              </div>
              <div className="details-nums">
                {numbersWithSpaces(
                  summery.allTenants?.cooling < 0
                    ? '0'
                    : summery.allTenants?.cooling.toFixed(0)
                )}{' '}
                GJ
              </div>
            </div>
            <div className="row">
              <SVG
                src={toAbsoluteUrl('/media/svg/cooling/heating.svg')}
                className="h-50"
              />
              <div className="col mr-0">
                <div className=" poppins  ">
                  <FormattedMessage id="TOTAL.LOSS" />
                </div>
                <div className="font-size-sm text-muted font-weight-bold">
                  <FormattedMessage id="HEATING.CONSUMPTION" />
                </div>
              </div>
              <div className="details-nums">
                {numbersWithSpaces(
                  summery.totalLoss?.heating < 0
                    ? '0'
                    : summery.totalLoss?.heating.toFixed(0)
                )}{' '}
                GJ
              </div>
            </div>
            <div className="row py-6">
              <SVG
                src={toAbsoluteUrl('/media/svg/cooling/cooling.svg')}
                className="h-50"
              />
              <div className="col mr-0">
                <div className="poppins">
                  <FormattedMessage id="TOTAL.LOSS" />
                </div>
                <div className="font-size-sm text-muted font-weight-bold">
                  <FormattedMessage id="COOLING.CONSUMPTION" />
                </div>
              </div>
              <div className="details-nums">
                {numbersWithSpaces(
                  summery.totalLoss?.cooling < 0
                    ? '0'
                    : summery.totalLoss?.cooling.toFixed(0)
                )}{' '}
                GJ
              </div>
            </div>
            <div className="row">
              <SVG
                src={toAbsoluteUrl('/media/svg/cooling/greencalc.svg')}
                className="h-50 "
              />
              <div className="col mr-0">
                <div className="poppins">
                  <FormattedMessage id="ACTIVE.DEVICES" />
                </div>
              </div>
              <div className="details-nums">{summery.devices?.active}</div>
            </div>
            <div className="row py-6">
              <SVG
                src={toAbsoluteUrl('/media/svg/cooling/redcalc.svg')}
                className="h-50 "
              />
              <div className="col mr-0">
                <div className="poppins">
                  <FormattedMessage id="INACTIVE.DEVICES" />
                </div>
              </div>
              <div className="details-nums">{summery.devices?.inactive}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
