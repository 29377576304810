// hooks
import { useQuery } from '@tanstack/react-query';
import apiRequest from '../../config/apiRequest';

const useSummeryRequest = () => {
  const { isLoading, data } = useQuery(['getSummery'], () =>
    apiRequest({
      method: 'GET',
      endpoint: 'org/devices/summery'
    })
  );

  return {
    summery: data?.data || [],
    isLoading
  };
};

export default useSummeryRequest;
