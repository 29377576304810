import React from 'react';
import { FormattedMessage } from 'react-intl';
import { numbersWithSpaces } from 'app/helpers/numberWithSpaces';

export default function TenantsDevicesTable({ devices }) {
  return (
    <div className="card card-custom">
      <div className="card-header border-0 py-5">
        <h3 className="card-title tabletitle align-items-start flex-column">
          <FormattedMessage id="DEVICES" />
        </h3>
        <div className="card-toolbar" />
      </div>
      <div className="card-body py-0">
        <div className="table-responsive">
          <table
            className="table table-head-custom tenants-devices-table table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr>
                <th className="width-158 thead-p">
                  <FormattedMessage id="DEVICE.ID" />
                </th>
                <th className="width-158 thead-p">
                  <FormattedMessage id="DEVICE.NAME" />
                </th>
                <th className="width-158 thead-p">
                  <FormattedMessage id="TYPE" />
                </th>
                <th className="width-158 thead-p">
                  <FormattedMessage id="STATUS" />
                </th>
                <th className="width-158 thead-p">
                  <FormattedMessage id="CONSUMPTION" />
                </th>
              </tr>
            </thead>
            <tbody>
              {devices.map((device, index) => {
                let totalEnergy = 0;

                totalEnergy =
                  device.billingInfo.deviceInfo.totalEnergy < 0
                    ? 0
                    : device.billingInfo.deviceInfo.totalEnergy;

                return (
                  <tr key={device.serialNumber + index}>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {device.serialNumber}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {device.deviceName}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {device.deviceType}
                      </span>
                    </td>
                    <td>
                      <span className="tenant-active">
                        <FormattedMessage id="ACTIVE" />
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {device?.id === 121
                          ? device.billingInfo.deviceInfo.totalEnergyDiff.toFixed(
                              2
                            )
                          : numbersWithSpaces(totalEnergy.toFixed(2))}{' '}
                        GJ
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
