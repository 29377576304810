// hooks
import { useQuery } from '@tanstack/react-query';
import apiRequest from '../../config/apiRequest';

const useTenantDevicesListRequest = (tenantId) => {
  const { isLoading, data, refetch } = useQuery(
    ['getTenantDevices', tenantId],
    () =>
      apiRequest({
        method: 'GET',
        endpoint: `org/tenant/${tenantId}/devices`
      })
  );

  return {
    tenantDevicesList: data?.data?.rows || [],
    tenantDevicesListIsLoading: isLoading,
    refetchTenantDevicesList: refetch
  };
};

export default useTenantDevicesListRequest;
