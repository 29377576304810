import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { ContentRoute, LayoutSplashScreen } from '_metronic/layout';
import TenantPage from '../tenant';

export default function TenantDashboard() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute exact path="/" component={TenantPage} />
      </Switch>
    </Suspense>
  );
}
