import axios from 'axios';
import { baseUrl } from 'app/config/baseUrl';

export const sendInvoice = async ({ tId, month, year, sale, setAlert }) => {
  const totalSale = sale !== undefined ? sale[tId] : 0;

  const axiosBody = {
    month,
    tenants: [
      {
        tenantId: tId,
        sale: totalSale
      }
    ]
  };

  if (month && year) {
    await axios
      .post(`${baseUrl}/billing/invoice/send/tenants`, axiosBody)
      .then(() => {
        setAlert('Invoice successfuly sent!');
      })
      .catch(() => {
        setAlert('Invoice is not generated!');
      });
  } else {
    setAlert('Please select period');
  }
};
