/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import clsx from 'clsx';
import { Dropdown } from 'react-bootstrap';

// custom hooks
import { setLanguage, useLang } from '_metronic/i18n';

// partials
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';

// helpers
import { toAbsoluteUrl } from '_metronic/_helpers';

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/svg/flags/226-united-states.svg')
  },
  {
    lang: 'ka',
    name: 'Georgian',
    flag: toAbsoluteUrl('/media/svg/flags/256-georgia.svg')
  }
];

const LanguageSelectorDropdown = () => {
  const lang = useLang();
  const currentLanguage = languages.find(x => x.lang === lang);

  return (
    <Dropdown drop="down">
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart"
      >
        <div className="btn-dropdown btn-lg mr-1 lang-flag">
          <img
            className="h-20px w-20px rounded-sm "
            src={currentLanguage.flag}
            alt={currentLanguage.name}
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu">
        <ul className="navi navi-hover py-4">
          {languages.map(language => (
            <li
              key={language.lang}
              className={clsx('navi-item', {
                active: language.lang === currentLanguage.lang
              })}
            >
              <a
                href="#"
                onClick={() => setLanguage(language.lang)}
                className="navi-link"
              >
                <span className="symbol symbol-20 mr-3">
                  <img src={language.flag} alt={language.name} />
                </span>
                <span className="navi-text">{language.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSelectorDropdown;
