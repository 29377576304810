import React from 'react';
import { FormattedMessage } from 'react-intl';
// import numbersWithSpaces from "../../../numberWithSpaces";

// hooks
// import useDevicesMonthDataRequest from "../../../hooks/useDevicesMonthDataRequest";
// import { currentDate } from 'app/config/getCurrentDate';

export default function OrgDevicesTable() {
  // const [unmatchedDevices, setUnmatchedDevices] = useState([]);
  // const { devicesMonthData, devicesMonthDataIsLoading } = useDevicesMonthDataRequest();

  // useEffect(() => {
  //   if (!devicesMonthDataIsLoading && devicesMonthData) {
  //     const currentMonthData = devicesMonthData.filter(item => item.month === currentDate());
  //     setUnmatchedDevices(currentMonthData.filter(item => item.Device.tenantId === null));
  //   }
  // }, [devicesMonthDataIsLoading, devicesMonthData]);

  return (
    <div className={`card card-custom`}>
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="tabletitle">
            <FormattedMessage id="DEVICES" />
          </span>
        </h3>
        <div className="card-toolbar" />
      </div>
      <div className="card-body py-0">
        <div className="table-responsive">
          <table
            className="table tenants-table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr>
                <th className="width-158 thead-p">
                  <FormattedMessage id="DEVICE.ID" />
                </th>
                <th className="width-158 thead-p">
                  <FormattedMessage id="DEVICE.NAME" />
                </th>
                <th className="width-158 thead-p">
                  <FormattedMessage id="TYPE" />
                </th>
                <th className="width-158 thead-p">
                  <FormattedMessage id="STATUS" />
                </th>
                <th className="width-158 thead-p">
                  <FormattedMessage id="CONSUMPTION" />
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {
                unmatchedDevices && unmatchedDevices.map(item => (
                  <tr key={item.Device.id}>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.serial_number}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.Device.deviceName}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {item.Device.deviceType}
                      </span>
                    </td>
                    <td>
                      <span className={`tenant-${item.Device.status.toLowerCase()}`}>
                        {item.Device.status}
                      </span>
                    </td>
                    <td className="td-text">
                      {numbersWithSpaces((item.heat_flow).toFixed(2))} GJ
                    </td>
                  </tr>
                ))
              } */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
