import axios from 'axios';
import { baseUrl } from 'app/config/baseUrl';

const sendAllInvoicesAxios = async ({ tId, month, year, sale, setAlert }) => {
  const totalSale = sale !== undefined ? sale[tId] : 0;

  const axiosBody = {
    month,
    tenants: [
      {
        tenantId: tId,
        sale: totalSale
      }
    ]
  };

  if (month && year) {
    await axios
      .post(`${baseUrl}/billing/invoice/send/tenants`, axiosBody)
      .then(() => {
        setAlert('Invoice successfuly sent!');
      })
      .catch(() => {
        setAlert('Invoice is not generated!');
      });
  } else {
    setAlert('Please select period');
  }
};

export const sendAllInvoices = ({
  month,
  year,
  checkedTenants,
  setAlert,
  sale
}) => {
  let axiosBody = [];
  let body = {
    month,
    tenants: axiosBody
  };

  if (month && year) {
    if (Object.keys(sale).length > 0 && checkedTenants.length > 0) {
      if (checkedTenants.length !== Object.keys(sale).length) {
        let checkSales = checkedTenants.filter(
          item => !sale.hasOwnProperty(item)
        );
        axiosBody.push({
          tenantId: parseInt(checkSales),
          sale: 0
        });
      }
      for (const [key, value] of Object.entries(sale)) {
        axiosBody.push({
          tenantId: parseInt(`${key}`),
          sale: Number(`${value}`)
        });
      }
      sendAllInvoicesAxios({ body, setAlert });
    } else if (checkedTenants.length > 0 && Object.keys(sale).length === 0) {
      for (let i = 0; i < checkedTenants.length; i++) {
        axiosBody.push({
          tenantId: parseInt(checkedTenants[i]),
          sale: 0
        });
      }
      sendAllInvoicesAxios({ body, setAlert });
    } else {
      setAlert('Please Select Tenants');
    }
  } else {
    setAlert('Please select period');
  }
};
