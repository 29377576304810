import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import AlertModal from 'app/components/modals/AlertModal';
import { baseUrl } from 'app/config/baseUrl';

export default function ForgotPasswordStep1({ username, setUsername }) {
  let history = useHistory();
  const [alert, setAlert] = useState('');
  const [status, setStatus] = useState(false);

  function requestOTP() {
    if (username.length === 0) {
      setAlert('Username is required!');
    } else {
      axios
        .post(`${baseUrl}/auth/request-password-reset-otp`, { username })
        .then(response => {
          setStatus(true);
        })
        .catch(e => {
          setAlert(e.response.data.message);
        });
    }
  }

  return (
    <div>
      {alert ? <AlertModal alert={alert} setAlert={setAlert} /> : null}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="authtitles">
          <FormattedMessage id="AUTH.FORGOT.TITLE" />
        </h3>
      </div>
      <div paddingBottom={2}>
        <p className="auth-2ndtitle">
          <FormattedMessage id="AUTH.INPUT.USERNAME" />
        </p>
        <input
          onChange={e => setUsername(e.target.value)}
          className="recovery-input"
        />
      </div>
      <div className="recovery-step1-div">
        <Link to="/auth/login">
          <button className="recovery-back-button" type="button">
            <FormattedMessage id="BACK" />
          </button>
        </Link>
        <button
          value={username}
          onClick={requestOTP}
          className="recovery-next2-button"
          type="submit"
        >
          {status ? history.push('/auth/forgot-password/otp') : null}
          <FormattedMessage id="NEXT" />
        </button>
      </div>
    </div>
  );
}
