import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { toAbsoluteUrl } from '_metronic/_helpers';
import { useHtmlClassService } from '_metronic/layout/_core/MetronicLayout';
import { Topbar } from './Topbar';
import { HeaderMenuWrapper } from './header-menu/HeaderMenuWrapper';

export function Header() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses('header_container', true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        'header.menu.self.display'
      )
    };
  }, [uiService]);

  return (
    <>
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          <div className="d-flex align-items-stretch mr-3">
            <div className="header-logo">
              <Link to="/">
                <img
                  height="45px"
                  width="84px"
                  className="logo-default mr-5"
                  alt="Logo"
                  src={toAbsoluteUrl('/media/qwerty/logo2.svg')}
                />
                <img
                  className="logo-sticky mr-5"
                  alt="Logo"
                  height="45px"
                  width="84px"
                  src={toAbsoluteUrl('/media/qwerty/logo.svg')}
                />
              </Link>
            </div>
            {/* end::Header Logo */}
            {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          </div>
          <Topbar />
        </div>
      </div>
    </>
  );
}
