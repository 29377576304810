// hooks
import { useQuery } from '@tanstack/react-query';

// config
import apiRequest from '../../config/apiRequest';

const useTenantPageRequest = ({ tenantId, getTenantId }) => {
  // get devices info for chart
  const { isLoading: tenantDevicesFlowIsLoading, data: devicesFlow } = useQuery(
    ['getTenantDevicesFlow', tenantId || getTenantId],
    () =>
      apiRequest({
        method: 'GET',
        endpoint: tenantId
          ? 'tenant/devices/month-data/by-device'
          : `org/tenant/${getTenantId}/devices/month-data/by-device`
      })
  );

  const { isLoading: tenantDevicesListIsLoading, data: devicesList } = useQuery(
    ['getTenantDevicesList', tenantId || getTenantId],
    () =>
      apiRequest({
        method: 'GET',
        endpoint: tenantId
          ? 'tenant/devices'
          : `org/tenant/${getTenantId}/devices`
      })
  );

  const {
    isLoading: tenantIsLoading,
    data: tenant,
    refetch: refetchTenant
  } = useQuery(['getTenant', tenantId || getTenantId], () =>
    apiRequest({
      method: 'GET',
      endpoint: tenantId ? 'tenant' : `org/tenant/${getTenantId}`
    })
  );

  const {
    isLoading: tenantUsersIsLoading,
    data: tenantUsers,
    refetch: refetchTenantUsers
  } = useQuery(['getTenantUsers', tenantId || getTenantId], () =>
    apiRequest({
      method: 'GET',
      endpoint: tenantId ? 'tenant/users' : `org/tenant/${getTenantId}/users`
    })
  );

  return {
    tenantDevicesFlow: devicesFlow?.data || [],
    tenantDevicesFlowIsLoading,
    tenantDevicesList: devicesList?.data?.rows || [],
    tenantDevicesListIsLoading,
    tenant: tenant?.data || [],
    tenantIsLoading,
    refetchTenant,
    tenantUsers: tenantId ? tenantUsers?.data : tenantUsers?.data?.rows || [],
    tenantUsersIsLoading,
    refetchTenantUsers
  };
};

export default useTenantPageRequest;
