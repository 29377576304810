/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-imports */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { toAbsoluteUrl } from '_metronic/_helpers';

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${50}%, -${50}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  }
}));

export function SplashScreen() {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <img
            className="qwerty-loading-img"
            src={toAbsoluteUrl('/media/qwerty-loading.gif')}
            alt="Qwerty logo"
          />
        </div>
      </Modal>
    </>
  );
}
