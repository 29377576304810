import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import OTPInput from 'otp-input-react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import AlertModal from 'app/components/modals/AlertModal';
import { baseUrl } from 'app/config/baseUrl';

let otp = 0;

export default function ForgotPasswordStep2({ username }) {
  const [OTP, setOTP] = useState(0);
  const [alert, setAlert] = useState('');
  let history = useHistory();

  function resend() {
    axios
      .post(`${baseUrl}/auth/request-password-reset-otp`, { username })
      .then(response => {
        setAlert('OTP sent!');
      })
      .catch(e => {
        setAlert(e.response.data.message);
      });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (alert === 'Password changed successfully!') {
        history.push('/auth/login');
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [alert]);

  otp = OTP;

  return (
    <Card className="forgot-container">
      {alert ? <AlertModal alert={alert} setAlert={setAlert} /> : null}
      <CardContent className="forgot-form">
        <FormikStepper
          otp={OTP}
          initialValues={{
            password: '',
            repeatPassword: ''
          }}
          onSubmit={values =>
            axios
              .post(`${baseUrl}/auth/reset-password`, {
                otp,
                password: values.password,
                confirmPassword: values.repeatPassword
              })
              .then(response => {
                setAlert('Password changed successfully!');
              })
              .catch(e => {
                setAlert(e.response.data.message);
              })
          }
        >
          <FormikStep>
            <div className="text-center">
              <p className="enter-otp-p">
                შეიყვანეთ 6 ნიშნა კოდი, <br /> რომელიც გამოგიგზავნეთ ელ-ფოსტაზე
              </p>
            </div>
            <div className="otp-div">
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
              />
            </div>
            <div className="try-again-otp">
              <p className="did-not-received-p">
                <FormattedMessage id="DIDN'T.RECEIVED" />
              </p>
              <p onClick={() => resend()} className="try-again-p">
                <FormattedMessage id="TRY.AGAIN" />
              </p>
            </div>
          </FormikStep>
          <FormikStep
            validationSchema={Yup.object({
              password: Yup.string().required('Required'),
              repeatPassword: Yup.string().required('Required')
            })}
          >
            <Box paddingBottom={2}>
              <FormattedMessage id="AUTH.INPUT.PASSWORD" />
              <br />
              <Field
                InputProps={{ disableUnderline: true }}
                autocomplete="off"
                name="password"
                className="recovery-input"
                type="password"
              />
            </Box>
            <Box paddingBottom={2}>
              <FormattedMessage id="REPEAT.PASSWORD" />
              <br />
              <Field
                autocomplete="off"
                InputProps={{ disableUnderline: true }}
                className="recovery-input"
                name="repeatPassword"
                type="password"
              />
            </Box>
          </FormikStep>
        </FormikStepper>
      </CardContent>
    </Card>
  );
}

function FormikStep({ children }) {
  return <>{children}</>;
}

function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  function isLastStep() {
    return step === 1;
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          // setStep(s => s + 1);
        } else {
          setStep(s => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      <Form autoComplete="off">
        {currentChild}
        <Grid container style={{ marginTop: 50 }} spacing={2}>
          {step === 0 ? (
            <>
              <Grid item>
                <Link to="auth/forgot-password">
                  <button className="recovery-back-button" type="button">
                    <FormattedMessage id="BACK" />
                  </button>
                </Link>
              </Grid>
            </>
          ) : (
            <Grid item>
              <button
                className="recovery-back-button"
                onClick={() => setStep(s => s - 1)}
                type="button"
              >
                <FormattedMessage id="BACK" />
              </button>
            </Grid>
          )}
          {step === 0 && otp.length !== 6 ? (
            <Grid item>
              <button
                className="recovery-next2-button"
                type="submit"
                disabled="true"
              >
                <FormattedMessage id="NEXT" />
              </button>
            </Grid>
          ) : step === 2 ? null : (
            <Grid item>
              <button className="recovery-next2-button">
                <FormattedMessage id="NEXT" />
              </button>
            </Grid>
          )}
        </Grid>
      </Form>
    </Formik>
  );
}
