// hooks
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/js/src/collapse.js';
import { numbersWithSpaces } from 'app/helpers/numberWithSpaces';
import { FormattedMessage } from 'react-intl';

// config
import { tenantIcons } from 'app/config/tenantIcons';

// helpers
import { toAbsoluteUrl } from '_metronic/_helpers';

// components
import SVG from 'react-inlinesvg';
import Toggle from '../../../components/hooks/Toggle';

// custom hooks
import useTenantDevicesListRequest from '../../../components/hooks/useTenantDevicesListRequest';
import { renderTenantIconByDomain } from 'app/helpers/renderTenantIconByDomain';

export default function TenantListItem({ item, handleOpenEdit, handleOpen }) {
  const [isOpened, setIsOpened] = useState(false);

  const {
    tenantDevicesList,
    tenantDevicesListIsLoading
  } = useTenantDevicesListRequest(isOpened ? item.id : 1);

  const tenantIcon =
    tenantIcons.find(tenant => tenant.id === item.id)?.icon ||
    renderTenantIconByDomain();

  const totalHeating = item.billingInfo
    ? item.billingInfo.heatingInfo.deviceInfo.totalEnergyInMonth
    : 0.0;

  const totalHeatingFlow = item.billingInfo
    ? item.billingInfo.heatingInfo.deviceInfo.totalFlowInMonth < 0
      ? item.billingInfo.heatingInfo.deviceInfo.totalFlowInMonth.toFixed(2) * -1
      : item.billingInfo.heatingInfo.deviceInfo.totalFlowInMonth.toFixed(2)
    : 0.0;

  const totalCooling = item.billingInfo
    ? item.billingInfo.coolingInfo.deviceInfo.totalEnergyInMonth.toFixed(2)
    : 0.0;

  const totalCoolingFlow = item.billingInfo
    ? item.billingInfo.coolingInfo.deviceInfo.totalFlowInMonth < 0
      ? item.billingInfo.coolingInfo.deviceInfo.totalFlowInMonth.toFixed(2) * -1
      : item.billingInfo.coolingInfo.deviceInfo.totalFlowInMonth.toFixed(2)
    : 0.0;

  return (
    <>
      <tr
        className={
          isOpened ? 'org-devices-table' : 'org-devices-table-border-none'
        }
      >
        <td className="pl-0 border-none">
          <div className="d-flex align-items-center">
            {item && (
              <div
                className={`tenant-icon-div ${
                  item.id !== 89 ? 'icon-border' : ''
                }`}
              >
                <img
                  className="tenant-icon"
                  src={tenantIcon}
                  alt={tenantIcon}
                />
              </div>
            )}
            <div className="table-vertical mt-4">
              <div className="ml-4">
                <Link to={`/tenant/${item.id}`}>
                  <span className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                    {item.name}
                  </span>
                </Link>
              </div>
              <span className="text-muted font-weight-bold text-muted d-block margin-left-12">
                {item.address}
              </span>
            </div>
          </div>
        </td>
        <td className="border-none nowrap">
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {totalHeating < 0
              ? numbersWithSpaces((0).toFixed(2))
              : numbersWithSpaces(totalHeating.toFixed(2))}{' '}
            GJ
          </span>
          <span className="text-muted font-weight-bold">
            {numbersWithSpaces(totalHeatingFlow)} (m3)
          </span>
        </td>
        <td className="border-none nowrap">
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {totalCooling < 0
              ? numbersWithSpaces((0).toFixed(2))
              : numbersWithSpaces(totalCooling)}{' '}
            GJ
          </span>
          <span className="text-muted font-weight-bold">
            {numbersWithSpaces(totalCoolingFlow)} (m3)
          </span>
        </td>
        <td style={{ paddingRight: '0px' }} className="border-none">
          <span className="tenant-active">
            <FormattedMessage id="ACTIVE" />
          </span>
        </td>
        <td className="border-none">
          <div className="btn btn-icon btn-bckg hoverr btn-sm mx-3">
            <span>
              <SVG
                type="button"
                onClick={() => handleOpenEdit(item)}
                src={toAbsoluteUrl(
                  '/media/svg/icons/Communication/Write-2.svg'
                )}
              />
            </span>
          </div>
          <div className="btn btn-icon btn-bckg hoverr btn-sm mx-3">
            <span>
              <SVG
                type="button"
                onClick={() => handleOpen(item)}
                src={toAbsoluteUrl('/media/svg/icons/General/Trash-2.svg')}
              />
            </span>
          </div>
          <div className="btn btn-icon btn-bckg hoverr btn-sm mx-3">
            <span onClick={() => setIsOpened(!isOpened)}>
              <Toggle />
            </span>
          </div>
        </td>
      </tr>
      {isOpened && !tenantDevicesListIsLoading && (
        <tr>
          <td className="org-devices-collapse-table" colSpan="99">
            <table className="table table-vertical-center">
              <tbody>
                <tr className="org-devices-border">
                  <td className="border-none td-title">
                    <FormattedMessage id="DEVICE.ID" />
                  </td>
                  <td className="border-none td-title"></td>
                  <td className="border-none td-title"></td>
                  <td className="border-none td-title">
                    <FormattedMessage id="TYPE" />
                  </td>
                  <td className="border-none td-title">
                    <FormattedMessage id="CONSUMPTION" />
                  </td>
                </tr>
                {tenantDevicesList?.map(device => {
                  const totalFlow =
                    device.billingInfo.deviceInfo.totalEnergy < 0
                      ? numbersWithSpaces((0).toFixed(2))
                      : device.billingInfo.deviceInfo.totalEnergy.toFixed(2);
                  return (
                    <tr key={device.serialNumber}>
                      <td className="border-none td-text">
                        {device.serialNumber}
                      </td>
                      <td className="border-none td-text" />
                      <td className="border-none td-text" />
                      <td className="border-none td-text">
                        {device?.deviceType}
                      </td>
                      <td className="border-none td-text">
                        {device?.id === 121
                          ? device.billingInfo.deviceInfo.totalEnergyDiff.toFixed(
                              2
                            )
                          : numbersWithSpaces(totalFlow)}{' '}
                        GJ
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}
