import axios from 'axios';
import { baseUrl } from 'app/config/baseUrl';

export const exportInvoice = ({ tId, invoicesData, setAlert, month, sale }) => {
  let inv = invoicesData.filter(invoice => invoice.id === tId)[0];
  const totalSale = sale !== undefined ? sale[tId] : 0;

  const tenantName = invoicesData
    .filter(inv => inv.id === tId)
    .map(e => e.name);

  if (!inv.coolingInvoice || !inv.heatingInvoice) {
    setAlert('Invoice is not generated');
  } else {
    axios({
      url: `${baseUrl}/billing/invoice/export/tenant/${tId}?month=${month}&sale=${totalSale || ''}`,
      method: 'GET',
      responseType: 'blob'
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Invoice ${tenantName}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {});
  }
};
