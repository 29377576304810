// hooks
import { useQuery } from '@tanstack/react-query';
import apiRequest from '../../config/apiRequest';

const useAllTenantsRequest = () => {
  const { isLoading, data, refetch } = useQuery(['getTenants'], () =>
    apiRequest({
      method: 'GET',
      endpoint: 'org/tenant'
    })
  );

  return {
    tenants: data?.data?.rows || [],
    tenantsIsLoading: isLoading,
    refetchTenants: refetch
  };
};

export default useAllTenantsRequest;
