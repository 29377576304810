/* eslint-disable no-restricted-imports */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '_metronic/_helpers';

function getModalStyle() {
  return {
    top: `10%`,
    left: `50%`,
    transform: `translate(-${50}%, -${50}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '100%',
    maxWidth: 600,
    borderRadius: 12,
    backgroundColor: '#F2F4F5',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none'
  },
  responseDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15
  }
}));

export default function AlertModal({ alert, setAlert, redirectToLogin }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    setAlert('');
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <div>
            <span className="closemodal" onClick={handleClose}>
              <SVG src={toAbsoluteUrl('/media/qwerty/closemodal.svg')} />
            </span>
          </div>
          <div className={classes.responseDiv}>
            <span className="alert-text">{alert}</span>
          </div>
        </div>
      </Modal>
    </div>
  );
}
