// hooks
import React, { useEffect, useRef, useState } from 'react';

// components
import { Box, Card, CardContent, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { toAbsoluteUrl } from '_metronic/_helpers';
import { baseUrl } from 'app/config/baseUrl';

export default function EditTariffPlanForm(props) {
  const [status, setStatus] = useState();

  const success = () => (
    <Box className="tenantwasadded">
      <div className="column-center">
        <span className="margin-top-50">
          <SVG src={toAbsoluteUrl('/media/svg/done.svg')} />
        </span>
        <p className="font-weight-bolder margin-top-50 font-16">
          <FormattedMessage id="TARIFF.WAS.EDITED" />
        </p>
      </div>
    </Box>
  );

  const error = () => (
    <Box className="tenantwasadded">
      <div className="column-center">
        <span className="margin-top-50">
          <SVG
            src={toAbsoluteUrl('/media/svg/Modal/deactivation-tenant.svg')}
          />
        </span>
        <p className="font-weight-bolder margin-top-50 font-16">
          <FormattedMessage id="TARIFF.WAS.NOT.EDITED" />
        </p>
      </div>
    </Box>
  );

  function check() {
    if (status === undefined) {
      return null;
    } else if (status === 200) {
      return success();
    } else {
      return error();
    }
  }

  const id = props.item.id;
  const link = `${baseUrl}/org/tariff/` + id;
  const formikRef = useRef();

  useEffect(() => {
    let data = {
      name: props.item.name,
      fixed: props.item.fixed,
      el_addon: props.item.el_addon
    };
    formikRef.current.setValues(data);
  }, [props.item]);

  return (
    <Card className="tariff-form-container">
      <CardContent>
        <FormikStepper
          innerRef={formikRef}
          className="tenantform"
          initialValues={{
            name: '',
            fixed: '',
            el_addon: ''
          }}
          onSubmit={async values => {
            axios
              .put(link, values)
              .then(response => {
                setStatus(response.status);
              })
              .catch(e => {
                setStatus(e.response.status);
                console.log(e.response);
              });
          }}
        >
          <FormikStep
            validationSchema={Yup.object({
              name: Yup.string()
                .min(1, 'Too Short!')
                .required('Required'),
              fixed: Yup.string()
                .min(1, 'Too Short!')
                .required('Required'),
              el_addon: Yup.string()
                .min(1, 'Too Short!')
                .required('Required')
            })}
          >
            <Box paddingBottom={2}>
              <span>
                <FormattedMessage id="NAME" />
              </span>
              <Field
                InputProps={{ disableUnderline: true }}
                name="name"
                className="usermodalinput"
                component={TextField}
              />
            </Box>
            <Box paddingBottom={2}>
              <span>
                <FormattedMessage id="FIXED.COST" />
              </span>
              <Field
                InputProps={{ disableUnderline: true }}
                className="usermodalinput"
                name="fixed"
                component={TextField}
              />
            </Box>
            <Box paddingBottom={2}>
              <span>
                <FormattedMessage id="ELECTRICITY.ADDON" />
              </span>
              <Field
                InputProps={{ disableUnderline: true }}
                className="usermodalinput"
                name="el_addon"
                component={TextField}
              />
            </Box>
          </FormikStep>

          <FormikStep>{check()}</FormikStep>
        </FormikStepper>
      </CardContent>
    </Card>
  );
}

export function FormikStep({ children }) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  function isLastStep() {
    return step === 0;
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setStep(s => s + 1);
        } else {
          setStep(s => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      <Form autoComplete="off">
        {step < 1 ? (
          <div>
            <Box
              style={{
                marginTop: 0,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <SVG
                src={toAbsoluteUrl('/media/svg/Modal/enterdetails.svg')}
              ></SVG>
            </Box>
            <p className="enterdetailsmodal">
              <FormattedMessage id="ENTER.DETAILS" />
            </p>
            <hr style={{ width: '100%' }} />
          </div>
        ) : null}
        {currentChild}
        <Grid container style={{ marginTop: 20 }} spacing={2}>
          <Grid item>
            {step === 0 ? (
              <button className="form-next-button1" type="submit">
                <FormattedMessage id="NEXT" />
              </button>
            ) : null}
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}
