import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'react-bootstrap';

const Button = ({ label, containerStyle, loading, spinnerColor, ...props }) => {
  return (
    <>
      <button className={containerStyle} {...props}>
        {loading ? (
          <Spinner
            size="md"
            animation="border"
            variant={spinnerColor || 'light'}
          />
        ) : (
          <FormattedMessage id={label} />
        )}
      </button>
    </>
  );
};

export default Button;
