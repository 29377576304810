// hooks
import React, { useContext, useEffect, useState } from 'react';

// libs
import moment from 'moment';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';

// styles
import '../Billing.scss';

// components
import SVG from 'react-inlinesvg';
import YearSelector from 'app/components/selector/YearSelector';
import MonthSelector from 'app/components/selector/MonthSelector';
import AlertModal from 'app/components/modals/AlertModal';

// helpers
import { toAbsoluteUrl } from '_metronic/_helpers';
import { currentDate } from 'app/config/getCurrentDate';
import { numbersWithSpaces } from 'app/helpers/numberWithSpaces';

// config
import { baseUrl } from 'app/config/baseUrl';

// context
import BillingContext from 'app/components/context/BillingContext';
import { extractSubdomain } from 'app/helpers/ExtractSubdomain';

const CoolingCalculation = ({ className, tariffsData }) => {
  const { coolingDate, setCoolingDate } = useContext(BillingContext);
  const [eComsumption, setEComsumption] = useState('');
  const [eTariff, setETariff] = useState('');
  const [additionalCost, setAdditionalCost] = useState('');
  const [month, setMonth] = useState(moment(coolingDate).format('YYYY-MM'));
  const [year, setYear] = useState(moment(coolingDate).format('YYYY'));
  const [gjInfo, setGjInfo] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [oneGjPrice, setOneGjPrice] = useState(0);
  const [waterTariff, setWaterTariff] = useState(0);
  const [waterConsumption, setWaterConsumption] = useState(0);
  const [alert, setAlert] = useState('');

  const subDomain = extractSubdomain();

  const isTbilisiCity = subDomain === 'tbilisicity';

  const axisAxiosBody = {
    eComsumption,
    eTariff,
    additionalCost,
    month
  };

  const tbilisicityAxiosBody = {
    eComsumption,
    eTariff,
    additionalCost,
    month,
    waterTariff,
    waterConsumption
  };

  useEffect(() => {
    async function fetchOldCalculations() {
      await axios
        .get(`${baseUrl}/billing/invoice/latest`, {
          params: { month, type: 'COOLING' }
        })
        .then(response => {
          setTotalAmount(response.data.totalPrice);
          setOneGjPrice(response.data.oneGjPrice);
          setEComsumption(response.data.eComsumption);
          setETariff(response.data.eTariff);
          setAdditionalCost(response.data.additionalCost);
          setCoolingDate(month);
          if (isTbilisiCity) {
            setWaterTariff(response.data?.waterTariff);
            setWaterConsumption(response.data?.waterConsumption);
          }
          axios
            .post(`${baseUrl}/billing/calculate/all-tariffs/cooling`, {
              eComsumption: response.data.eComsumption,
              eTariff: response.data.eTariff,
              additionalCost: response.data.additionalCost,
              month,
              waterTariff: response.data?.waterTariff,
              waterConsumption: response.data?.waterConsumption
            })
            .then(response => {
              setGjInfo(response.data);
            })
            .catch(() => {});
        })
        .catch(e => {
          if (e.response.status === 404) {
            setTotalAmount(0);
            setOneGjPrice(0);
            setEComsumption(0);
            setETariff(0);
            setAdditionalCost(0);
            setGjInfo(0);
            setWaterTariff(0);
            setWaterConsumption(0);
          }
        });
    }

    if (month && year) {
      fetchOldCalculations();
    }
  }, [month, year]);

  const onSubmit = async e => {
    e.preventDefault();

    await axios
      .post(
        `${baseUrl}/billing/calculate/all-tariffs/cooling`,
        isTbilisiCity ? tbilisicityAxiosBody : axisAxiosBody
      )
      .then(response => {
        setTotalAmount(response.data[0].priceInfo.totalPrice);
        setOneGjPrice(response.data[0].priceInfo.oneGjPrice);
        setGjInfo(response.data);

        axios
          .post(
            `${baseUrl}/billing/create/cooling`,
            isTbilisiCity ? tbilisicityAxiosBody : axisAxiosBody
          )
          .then(() => {})
          .catch(e => {
            setAlert(e.response.data.message);
          });
      })
      .catch(e => {
        setAlert(e.response.data.message);
      });
  };

  return (
    <div className={`card card-custom ${className}`}>
      {alert && <AlertModal alert={alert} setAlert={setAlert} />}
      <div className="card-body billing-calculation-container pt-0">
        <div className="d-flex flex-wrap align-items-center mt-10">
          <div className="symbol symbol-45 symbol-light mr-5">
            <span>
              <SVG
                className="h-50 align-self-center"
                src={toAbsoluteUrl('/media/svg/billing/coolbilling.svg')}
              />
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1">
            <h3 className="billingblue">
              <FormattedMessage id="COOLING.CALCULATION" />
            </h3>
          </div>
          <div className="selector-div">
            <YearSelector
              onChange={e => {
                setYear(e.target.value);
              }}
              value={year}
            />
            <MonthSelector
              onChange={e => {
                setMonth(e.target.value);
              }}
              value={month}
              year={year}
            />
          </div>
        </div>
        <div className="mt-10 d-flex justify-space-between">
          <div className="width-260">
            <form autoComplete="off" onSubmit={onSubmit}>
              <label>
                <FormattedMessage id="ELECTRICITY.TARIFF" />
              </label>
              <br />
              <input
                value={eTariff}
                onChange={e => setETariff(e.target.value)}
                type="text"
                className="calculationinput"
              />
              <br />
              <label>
                <FormattedMessage id="ELECTRICITY.CONSUMPTION" />
              </label>
              <br />
              <input
                value={eComsumption}
                onChange={e => setEComsumption(e.target.value)}
                type="text"
                className="calculationinput"
              />
              <br />
              <label>
                <FormattedMessage id="ADDITIONAL.COST" />
              </label>
              <br />
              <input
                value={additionalCost}
                onChange={e => setAdditionalCost(e.target.value)}
                type="text"
                className="calculationinput"
              />
              {isTbilisiCity && (
                <>
                  <label>
                    <FormattedMessage id="WATER.TARIFF" />
                  </label>
                  <br />
                  <input
                    value={waterTariff}
                    onChange={e => setWaterTariff(e.target.value)}
                    type="text"
                    className="calculationinput"
                  />
                  <label>
                    <FormattedMessage id="WATER.CONSUMPTION" />
                  </label>
                  <br />
                  <input
                    value={waterConsumption}
                    onChange={e => setWaterConsumption(e.target.value)}
                    type="text"
                    className="calculationinput"
                  />
                </>
              )}
              <br />
              <button type="submit" className="savebtn2">
                <FormattedMessage id="SAVE" />
              </button>
            </form>
          </div>
          <div>
            <div className="total-amount-div vertical-border">
              <p className="total-amount-text">
                <FormattedMessage id="TOTAL.AMOUNT" />
              </p>
              <p className="total-amount">
                {numbersWithSpaces(totalAmount.toFixed(2))} (₾)
              </p>
              <div className="cost-div">
                <p className="cost-gj">
                  <FormattedMessage id="1.GJ.COST" />
                </p>
                <p className="cost-num"> {oneGjPrice.toFixed(4)} ₾</p>
                {tariffsData.map(e => {
                  let calculatedGj = gjInfo
                    ? gjInfo
                        .filter(item => item.tariff.id === e.id)
                        .map(item => item.priceInfo.oneGjTariffCost.toFixed(4))
                    : '0.00';
                  return (
                    <div key={e.id}>
                      <p className="cost-gj">
                        <FormattedMessage id="1.GJ.COST" />
                        (Tariff {e.name})
                      </p>
                      <p className="cost-num">{calculatedGj} ₾</p>
                    </div>
                  );
                })}
                <span className="cost-gj date">
                  <FormattedMessage id="DATE" />
                </span>
                <span className="cost-num">{currentDate()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoolingCalculation;
