import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';

// pages
import TenantPage from '../tenant';
import BillingPage from '../billing';
import Reporting from '../reporting/Reporting';
import MainPage from '../main';
import InvoicesPage from '../invoices';

import { ContentRoute, LayoutSplashScreen } from '_metronic/layout';

export default function OrgDashboard() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/dashboard" component={MainPage} />
        <ContentRoute path="/invoices" component={InvoicesPage} />
        <ContentRoute path="/reporting" component={Reporting} />
        <ContentRoute path="/billing" component={BillingPage} />
        <ContentRoute exact path="/tenant/:id" component={TenantPage} />
      </Switch>
    </Suspense>
  );
}
