// hooks
import { useQuery } from '@tanstack/react-query';

// utils
import apiRequest from '../../config/apiRequest';

const useDevicesFlowRequest = (tenantId) => {
  const { isLoading, data: devicesFlowData } = useQuery(
    ['getDevicesFlow', tenantId],
    () =>
      apiRequest({
        method: 'GET',
        endpoint: `org/tenant/${tenantId}/devices/flow`
      })
  );

  return {
    devicesFlow: devicesFlowData?.data?.rows || [],
    isLoading
  };
};

export default useDevicesFlowRequest;
