import { Box, Card, CardContent, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import AddUsersToTenant from '../../../../components/modals/AddUsersToTenantModal';
import { toAbsoluteUrl } from '_metronic/_helpers';
import { baseUrl } from 'app/config/baseUrl';

export default function AddTenantForm() {
  const [data, setData] = useState([]);
  const [tariffId, setTariffId] = useState(null);
  const [status, setStatus] = useState();
  const [err, setErr] = useState('');
  const [tenantId, setTenantId] = useState();
  const type = 'ALL';

  useEffect(() => {
    if (data && data.length > 0) {
      setTariffId(data[0].id);
    }
  }, [data]);

  const success = () => (
    <Box className="tenantwasadded">
      <div className="column-center">
        <span className="margin-top-50">
          <SVG src={toAbsoluteUrl('/media/svg/done.svg')} />
        </span>
        <p className="font-weight-bolder margin-top-50 font-16">
          <FormattedMessage id="TENANT.WAS.ADDED" />
        </p>
        <hr className="tenant-add-hr" />
      </div>
      <AddUsersToTenant
        tenantId={tenantId}
        style={{ height: '56px', width: '420px' }}
      />
    </Box>
  );

  const error = () => (
    <Box className="tenantwasadded">
      <div className="column-center">
        <span className="margin-top-50">
          <SVG
            src={toAbsoluteUrl('/media/svg/Modal/deactivation-tenant.svg')}
          />
        </span>
        <p className="font-weight-bolder margin-top-50 font-16">
          Tenant was not Added :{err}
        </p>
      </div>
    </Box>
  );

  function check() {
    if (status === undefined) {
      return null;
    } else if (status === 200) {
      return success();
    } else {
      return error();
    }
  }

  function fetchTariffs() {
    axios
      .get(`${baseUrl}/org/tariff`)
      .then(response => {
        setData(response.data.rows);
      })
      .catch(e => {
        console.log(e.response);
      });
  }

  useEffect(() => {
    fetchTariffs();
  }, []);

  return (
    <Card className="tenantcontainer">
      <CardContent>
        <FormikStepper
          className="tenantform"
          initialValues={{
            name: '',
            id_code: '',
            address: '',
            email: '',
            activation_date: ''
          }}
          onSubmit={async values => {
            axios
              .post(`${baseUrl}/org/tenant`, values)
              .then(response => {
                setTenantId(response.data.id);
                setStatus(response.status);
                axios
                  .post(`${baseUrl}/org/tariff/match`, {
                    tariffId: String(tariffId),
                    type,
                    tenantId: String(response.data.id)
                  })
                  .then(() => {})
                  .catch(e => {
                    console.log(e.response);
                  });
              })
              .catch(e => {
                setStatus(e.response.status);
                setErr(e.response.data.reason);
                console.log('err', e.response?.data?.message);
              });
          }}
        >
          <FormikStep
            validationSchema={Yup.object({
              name: Yup.string()
                .min(2, 'Too Short!')
                .required('Required'),
              id_code: Yup.string()
                .min(2, 'Too Short!')
                .max(9, 'Too long')
                .required('Required')
            })}
          >
            <Box paddingBottom={2}>
              <FormattedMessage id="TENANT.NAME" />
              <br />
              <Field
                InputProps={{ disableUnderline: true }}
                name="name"
                className="usermodalinput"
                component={TextField}
              />
            </Box>
            <Box paddingBottom={2}>
              <FormattedMessage id="TENANT.ID" />
              <br />
              <Field
                InputProps={{ disableUnderline: true }}
                className="usermodalinput"
                name="id_code"
                component={TextField}
              />
            </Box>
          </FormikStep>
          <FormikStep
            validationSchema={Yup.object({
              address: Yup.string().required('Required'),
              email: Yup.string()
                .email('Invalid email')
                .required('Required')
            })}
          >
            <Box paddingBottom={2}>
              <FormattedMessage id="TENANT.ADRESS" />
              <br />
              <Field
                InputProps={{ disableUnderline: true }}
                name="address"
                className="usermodalinput"
                component={TextField}
              />
            </Box>
            <Box paddingBottom={2}>
              <FormattedMessage id="EMAIL" />
              <br />
              <Field
                InputProps={{ disableUnderline: true }}
                className="usermodalinput"
                name="email"
                component={TextField}
              />
            </Box>
          </FormikStep>
          <FormikStep
            validationSchema={Yup.object({
              activation_date: Yup.string().required('Required')
            })}
          >
            <Box paddingBottom={2}>
              <FormattedMessage id="TENANT.ACTIVATION.TIME" />
              <br />
              <Field
                InputProps={{ disableUnderline: true }}
                className="usermodalinput"
                type="date"
                name="activation_date"
                component={TextField}
              />
            </Box>
            <Box paddingBottom={2}>
              <FormattedMessage id="TARIFF.PLAN" />
              <br />
              <Field
                InputProps={{ disableUnderline: true }}
                as="select"
                name="tariff"
                value={tariffId}
                onChange={e => {
                  setTariffId(e.target.value);
                }}
                className="usermodalinput"
                style={{ display: 'block', width: '100%' }}
              >
                {data.map(item => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </Field>
            </Box>
          </FormikStep>
          <FormikStep>{check()}</FormikStep>
        </FormikStepper>
      </CardContent>
    </Card>
  );
}

export function FormikStep({ children }) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  function isLastStep() {
    return step === childrenArray.length - 2;
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setStep(s => s + 1);
        } else {
          setStep(s => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      <Form autoComplete="off">
        {step < 3 ? (
          <div>
            <Box
              style={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <SVG
                src={toAbsoluteUrl('/media/svg/Modal/enterdetails.svg')}
              ></SVG>
            </Box>
            <p className="enterdetailsmodal">
              <FormattedMessage id="ENTER.DETAILS" />
            </p>
            <hr style={{ width: '100%' }} />
          </div>
        ) : null}
        {currentChild}
        <Grid container style={{ marginTop: 50 }} spacing={2}>
          {step === 3 || step === 0 ? null : (
            <Grid item>
              <button
                className="form-back-button"
                onClick={() => setStep(s => s - 1)}
              >
                <FormattedMessage id="BACK" />
              </button>
            </Grid>
          )}

          <Grid item>
            {step === 0 ? (
              <button className="form-next-button1" type="submit">
                <FormattedMessage id="NEXT" />
              </button>
            ) : step === 3 ? null : (
              <button className="form-next-button2" type="submit">
                <FormattedMessage id="NEXT" />
              </button>
            )}
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}
