// hooks
import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

// bootstrap
import 'bootstrap/js/src/collapse.js';

// helpers
import { toAbsoluteUrl } from '_metronic/_helpers';

// compponents
import { Box, Grid, makeStyles, Modal } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import AddTenantsModal from './modals/AddTenantsModal';
import DeleteTenantModal from './modals/DeleteTenantModal';
import EditTenantForm from './forms/EditTenantForm';
import TenantListItem from './TenantListItem';

// partials
import { SplashScreen } from '../../../../_metronic/_partials/controls';

// custom hooks
import useAllTenantsRequest from 'app/components/hooks/useAllTenantsRequest';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 586,
    height: 367,
    borderRadius: 12,
    backgroundColor: '#F2F4F5',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  paper2: {
    position: 'absolute',
    width: 586,
    height: 600,
    borderRadius: 12,
    backgroundColor: '#F2F4F5',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  root: {
    width: '100%'
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export default function TenantsTable() {
  const [openedTenant, setOpenedTenant] = useState(null);
  const [openEdit, setOpenEdit] = useState(null);
  const [tenantsList, setTenantsList] = useState([]);
  const [search, setSearch] = useState('');
  const [editTenantId, setEditTenantId] = useState(null);
  const initialData = useRef(null);
  const { tenants, tenantsIsLoading, refetchTenants } = useAllTenantsRequest();

  useEffect(() => {
    if (tenants && !tenantsIsLoading) {
      const activeTenants = tenants.filter(
        item => item.deactivation_date === null
      );
      const sortTenants = activeTenants.sort((a, b) =>
        a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
      );
      setTenantsList(sortTenants);
      initialData.current = sortTenants;
    }
  }, [tenants, tenantsIsLoading]);

  //search tenants start
  useEffect(() => {
    if (search.length > 0) {
      const filteredItems = tenantsList.filter(item => {
        let matches = false;
        for (let key in item) {
          if (
            String(item[key])
              .toLowerCase()
              .indexOf(search) > -1
          ) {
            matches = true;
            break;
          }
        }
        return matches;
      });
      setTenantsList(filteredItems);
    } else {
      setTenantsList(initialData.current);
    }
  }, [search]);
  //search tenants end

  /* begin::Modal */
  function getModalStyle() {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-${50}%, -${50}%)`
    };
  }

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const handleOpen = item => {
    setOpenedTenant(item);
  };

  const handleClose = () => {
    setOpenedTenant(null);
    refetchTenants();
  };

  //delete tenant start
  function deleteTenantBody(tenantItem) {
    if (tenantItem === null) {
      return null;
    }

    return (
      <div style={modalStyle} className={classes.paper}>
        <span className="closemodal" onClick={handleClose}>
          <SVG
            className="closemodal"
            src={toAbsoluteUrl('/media/qwerty/closemodal.svg')}
          />
        </span>
        <div className="deletetenantcontainer bg-white">
          <Box>
            <p className="delete-question">
              <FormattedMessage id="DELETE.TENANT.QUESTION.1" />
              <br />
              <FormattedMessage id="DELETE.TENANT.QUESTION.2" />
            </p>
            <hr />
          </Box>
          <Grid container spacing={2} className="deletebtngrid">
            <Grid item>
              <button
                className="delete-no"
                onClick={() => {
                  handleClose();
                }}
              >
                <FormattedMessage id="NO" />
              </button>
            </Grid>
            <Grid item>
              <button
                className="delete-yes"
                onClick={() => {
                  setOpenedTenant(null);
                }}
              >
                <FormattedMessage id="YES" />
              </button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  //edit tenant start

  const handleOpenEdit = item => {
    setOpenEdit(item);
    setEditTenantId(item.id);
  };

  const handleCloseEdit = () => {
    setOpenEdit(null);
    setEditTenantId(null);
    refetchTenants();
  };

  function editTenantBody(tenantItem) {
    if (tenantItem === null) {
      return null;
    }

    return (
      <>
        <div style={modalStyle} className={classes.paper2}>
          <span className="closemodal" onClick={handleCloseEdit}>
            <SVG
              className="closemodal"
              src={toAbsoluteUrl('/media/qwerty/closemodal.svg')}
            />
          </span>
          <div>
            <EditTenantForm tenantId={editTenantId} />
          </div>
        </div>
      </>
    );
  }

  // edit tenant end
  /* end::Modal */

  return (
    <>
      {tenantsIsLoading ? (
        <SplashScreen />
      ) : (
        <div className="card card-custom">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="tabletitle">
                <FormattedMessage id="TENANTS" />
              </span>
            </h3>
            <div>
              <AddTenantsModal refetchTenants={refetchTenants} />
            </div>
          </div>
          <div className="tenant-search">
            <form className="form-inline">
              <input
                value={search}
                onChange={e => setSearch(e.target.value)}
                className="form-control reporting-search mr-sm-2 tenant-search-input"
                type="text"
                placeholder="Search"
              />
            </form>
          </div>
          <div className="card-body py-0">
            <div className="table-responsive">
              <table className="table table-head-custom tenants-table table-vertical-center">
                <thead className="thead-tenant">
                  <tr>
                    <th className="min-width-100 thead-p">
                      <FormattedMessage id="NAME" />
                    </th>
                    <th className="width-100px thead-p">
                      <FormattedMessage id="HEATING" />
                    </th>
                    <th className="width-100px thead-p">
                      <FormattedMessage id="COOLING" />
                    </th>
                    <th className="width-50 thead-p">
                      <FormattedMessage id="STATUS" />
                    </th>
                    <th className="width-test thead-p" />
                  </tr>
                </thead>
                <tbody>
                  {tenantsList &&
                    tenantsList.map((item, index) => (
                      <TenantListItem
                        key={index}
                        item={item}
                        handleOpenEdit={handleOpenEdit}
                        handleOpen={handleOpen}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* //delete tenant modal start */}
          <Modal
            open={openedTenant !== null}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {deleteTenantBody(openedTenant !== null)}
          </Modal>
          <DeleteTenantModal
            tenantId={openedTenant?.id}
            handleClose={handleClose}
          />
          {/* //delete tenant modal end */}

          {/* //edit tenant modal start */}
          <Modal
            open={openEdit !== null}
            onClose={handleCloseEdit}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {editTenantBody(openEdit !== null)}
          </Modal>
          {/* //edit tenant modal start */}
        </div>
      )}
    </>
  );
}
