import React, { useMemo } from 'react';
import objectPath from 'object-path';
import { useHtmlClassService } from '_metronic/layout/_core/MetronicLayout';
import { HeaderMenu } from './HeaderMenu';
import { shallowEqual, useSelector } from 'react-redux';
import { AdminHeader } from './AdminHeader';

export function HeaderMenuWrapper() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      ktMenuClasses: uiService.getClasses('header_menu', true),
      rootArrowEnabled: objectPath.get(
        uiService.config,
        'header.menu.self.root-arrow'
      ),
      menuDesktopToggle: objectPath.get(
        uiService.config,
        'header.menu.desktop.toggle'
      ),
      headerMenuAttributes: uiService.getAttributes('header_menu'),
      headerSelfTheme: objectPath.get(uiService.config, 'header.self.theme'),
      ulClasses: uiService.getClasses('header_menu_nav', true),
      disabledAsideSelfDisplay:
        objectPath.get(uiService.config, 'aside.self.display') === false
    };
  }, [uiService]);

  const { userType } = useSelector(({ auth }) => {
    return {
      isAuthorized: auth.user != null,
      userType: auth.user ? auth.user.userType : null
    };
  }, shallowEqual);

  function renderDashboard(role) {
    if (role === 'ORG') {
      return <HeaderMenu layoutProps={layoutProps} />;
    } else if (role === 'ADMIN') {
      return <AdminHeader layoutProps={layoutProps} />;
    } else if (role === 'TENANT') {
      return null;
    }
  }

  return (
    <>
      {/*begin::Header Menu Wrapper*/}
      <div
        className="header-menu-wrapper header-menu-wrapper-left"
        id="kt_header_menu_wrapper"
      >
        {/*begin::Header Menu*/}
        {renderDashboard(userType)}
        {/*end::Header Menu*/}
      </div>
      {/*Header Menu Wrapper*/}
    </>
  );
}
