import axios from 'axios';

// config
import { baseUrl } from './baseUrl';

export default async function apiRequest({ method, endpoint, ...params }) {
  const axiosParams = {
    method: method || 'GET',
    url: `${baseUrl}/${endpoint}`
  };

  if (method === 'POST') {
    axiosParams.data = {
      ...params.data
    };
  } else {
    axiosParams.params = {
      ...params
    };
  }
  return axios(axiosParams).then(response => {
    return response;
  });
}
