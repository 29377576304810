import moment from 'moment';
import { baseUrl } from 'app/config/baseUrl';

import axios from 'axios';

let monthsGjhData = {};

for (let i = 1; i <= 12; i++) {
  const key = moment()
    .subtract(i, 'months')
    .format('YYYY-MM');
  monthsGjhData[key] = {
    HEATING: 0,
    COOLING: 0,
    heatingFinalCost: null,
    coolingFinalCost: null
  };
}

const monthsGjhDataItems = Object.values(monthsGjhData);

function h(e) {
  return monthsGjhDataItems[e].HEATING.toFixed(2);
}

function c(e) {
  return monthsGjhDataItems[e].COOLING.toFixed(2);
}

let year = [];
for (let i = 12; i >= 1; i--) {
  year.push(
    moment()
      .subtract(i, 'months')
      .format('MMM')
  );
}

let halfYear = [];
for (let i = 6; i >= 1; i--) {
  halfYear.push(
    moment()
      .subtract(i, 'months')
      .format('MMM')
  );
}

let quarterYear = [];
for (let i = 3; i >= 1; i--) {
  quarterYear.push(
    moment()
      .subtract(i, 'months')
      .format('MMM')
  );
}

function getMonths(value) {
  switch (value) {
    case 'year':
      return {
        categories: year
      };
    case '6month':
      return {
        categories: halfYear
      };
    case '3month':
      return {
        categories: quarterYear
      };
    default:
  }
}

function getSeries(value) {
  switch (value) {
    case 'year':
      return [
        {
          name: '', //heating
          data: [
            h(11),
            h(10),
            h(9),
            h(8),
            h(7),
            h(6),
            h(5),
            h(4),
            h(3),
            h(2),
            h(1),
            h(0)
          ]
        },
        {
          name: '', //cooling
          data: [
            c(11),
            c(10),
            c(9),
            c(8),
            c(7),
            c(6),
            c(5),
            c(4),
            c(3),
            c(2),
            c(1),
            c(0)
          ]
        }
      ];
    case '6month':
      return [
        {
          data: [h(5), h(4), h(3), h(2), h(1), h(0)]
        },
        {
          data: [c(5), c(4), c(3), c(2), c(1), c(0)]
        }
      ];
    case '3month':
      return [
        {
          data: [h(2), h(1), h(0)]
        },
        {
          data: [c(2), c(1), c(0)]
        }
      ];
    default:
  }
}

const exportInvoice = async ({
  getTenantId,
  month,
  setIsLoading,
  setAlert
}) => {
  setIsLoading(true);
  const tid = getTenantId() || '-1';
  await axios({
    url: `${baseUrl}/billing/invoice/export/tenant/${tid}/?month=${month}`,
    method: 'GET',
    responseType: 'blob'
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Invoice.pdf`);
      document.body.appendChild(link);
      link.click();
      setIsLoading(false);
    })
    .catch(error => {
      setIsLoading(false);
      if (error.response.status) {
        setAlert('Invoice is not generated');
      }
    });
};

const getTenantInvoices = async ({ getTenantId, tenantId, monthsGjhData }) => {
  await axios
    .get(
      tenantId
        ? `${baseUrl}/tenant/invoices`
        : `${baseUrl}/billing/invoice/${getTenantId()}`
    )
    .then(response => {
      response.data.sort((a, b) => b.id - a.id);
      response.data.forEach(item => {
        const monthKey = moment.utc(item.endDate).format('YYYY-MM');
        if (
          item.invoiceType === 'HEATING' &&
          monthsGjhData[monthKey]?.heatingFinalCost === null
        ) {
          monthsGjhData[monthKey].heatingFinalCost = item.finalCost;
        } else if (
          item.invoiceType === 'COOLING' &&
          monthsGjhData[monthKey]?.coolingFinalCost === null
        ) {
          monthsGjhData[monthKey].coolingFinalCost = item.finalCost;
        }
      });
    })
    .catch(() => {});
};

export {
  exportInvoice,
  getMonths,
  getSeries,
  getTenantInvoices,
  monthsGjhData
};
