import Agrohub from '../assets/icons/agrohub.png';
import Gcf from '../assets/icons/gcf.png';
import Terminal from '../assets/icons/terminal.jpg';
import Pasha from '../assets/icons/pasha.jpeg';

export const tenantIcons = [
  {
    id: 89,
    icon: Agrohub
  },
  {
    id: 116,
    icon: Gcf
  },
  {
    id: 115,
    icon: Terminal
  },
  {
    id: 98,
    icon: Pasha
  }
];
