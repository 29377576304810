import { Box, Card, CardContent, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useState } from 'react';
import * as Yup from 'yup';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '_metronic/_helpers';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

// config
import { baseUrl } from 'app/config/baseUrl';

export default function UserForm() {
  let params = useParams();
  const { tenantId } = useSelector(({ auth }) => {
    return {
      tenantId: auth.user ? auth.user.tenantId : null
    };
  }, shallowEqual);

  function id() {
    if (tenantId === undefined) {
      return params.id;
    } else {
      return tenantId;
    }
  }

  const [status, setStatus] = useState();
  const [err, setErr] = useState('');

  const success = () => (
    <Box className="tenantwasadded">
      <div className="column-center">
        <span className="margin-top-50">
          <SVG src={toAbsoluteUrl('/media/svg/done.svg')} />
        </span>
        <p className="font-weight-bolder margin-top-50 font-16">
          <FormattedMessage id="USER.WAS.ADDED" />
        </p>
      </div>
    </Box>
  );

  const error = () => (
    <Box className="tenantwasadded">
      <div className="column-center">
        <span className="margin-top-50">
          <SVG
            src={toAbsoluteUrl('/media/svg/Modal/deactivation-tenant.svg')}
          />
        </span>
        <p className="font-weight-bolder margin-top-50 font-16">
          <FormattedMessage id="USER.WAS.NOT.ADDED" />: {err}
        </p>
      </div>
    </Box>
  );

  function check() {
    if (status === undefined) {
      return null;
    } else if (status === 200) {
      return success();
    } else {
      return error();
    }
  }

  return (
    <Card className="usercontainer">
      <CardContent>
        <FormikStepper
          className="userform"
          initialValues={{
            firstName: '',
            lastName: '',
            username: '',
            phone: '',
            email: ''
          }}
          onSubmit={values => {
            axios
              .post(`${baseUrl}/org/tenant/` + id() + '/users', values)
              .then(response => {
                setStatus(response.status);
              })
              .catch(e => {
                console.log(e.response);
                setErr(e.response.data.reason);
                setStatus(e.response.status);
              });
          }}
        >
          <FormikStep
            validationSchema={Yup.object({
              firstName: Yup.string().required('Required'),
              lastName: Yup.string().required('Required')
            })}
          >
            <Box paddingBottom={2}>
              <p className="input-p">
                <FormattedMessage id="FIRST.NAME" />
              </p>
              <Field
                InputProps={{ disableUnderline: true }}
                className="usermodalinput"
                name="firstName"
                component={TextField}
              />
            </Box>
            <Box paddingBottom={2}>
              <p className="input-p">
                <FormattedMessage id="LAST.NAME" />
              </p>
              <Field
                InputProps={{ disableUnderline: true }}
                className="usermodalinput"
                name="lastName"
                component={TextField}
              />
            </Box>
          </FormikStep>
          <FormikStep
            validationSchema={Yup.object({
              username: Yup.string().required('Required'),
              phone: Yup.string().required('Required'),
              email: Yup.string()
                .email('Invalid email')
                .required('Required')
            })}
          >
            <Box paddingBottom={2}>
              <p className="input-p">
                <FormattedMessage id="USERNAME" />
              </p>
              <Field
                InputProps={{ disableUnderline: true }}
                name="username"
                className="usermodalinput"
                component={TextField}
              />
            </Box>
            <Box paddingBottom={2}>
              <p className="input-p">
                <FormattedMessage id="EMAIL" />
              </p>
              <Field
                InputProps={{ disableUnderline: true }}
                name="email"
                className="usermodalinput"
                component={TextField}
              />
            </Box>
            <Box paddingBottom={2}>
              <p>
                <FormattedMessage id="PHONE" />
              </p>
              <Field
                InputProps={{ disableUnderline: true }}
                name="phone"
                className="usermodalinput"
                component={TextField}
              />
            </Box>
          </FormikStep>
          <FormikStep>{check()}</FormikStep>
        </FormikStepper>
      </CardContent>
    </Card>
  );
}

export function FormikStep({ children }) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  function isLastStep() {
    return step === 1;
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setStep(s => s + 1);
        } else {
          setStep(s => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      <Form autoComplete="off">
        {step < 2 ? (
          <div>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <SVG src={toAbsoluteUrl('/media/svg/Modal/enterdetails.svg')} />
            </Box>
            <p className="enterdetailsmodal">
              <FormattedMessage id="ENTER.DETAILS" />
            </p>
            <hr style={{ width: '100%' }} />
          </div>
        ) : null}
        {currentChild}
        <Grid container spacing={2}>
          {step === 2 || step === 0 ? null : (
            <Grid item>
              <button
                className="form-back-button"
                onClick={() => setStep(s => s - 1)}
              >
                Back
              </button>
            </Grid>
          )}

          <Grid item>
            {step === 0 ? (
              <button className="form-next-button1" type="submit">
                Next
              </button>
            ) : step === 2 ? null : (
              <button className="form-next-button2" type="submit">
                Next
              </button>
            )}
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}
