/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Modal } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '_metronic/_helpers';
import { baseUrl } from 'app/config/baseUrl';

// custom hooks
import useTenantPageRequest from '../../../components/hooks/useTenantPageRequest';
import { useMyInfoRequest } from '../../../components/hooks/useMyInfoRequest';

export default function TenantDetails({ tenantId, getTenantId }) {
  const { tenant, refetchTenant } = useTenantPageRequest({
    tenantId,
    getTenantId: getTenantId()
  });
  const [tariffs, setTariffs] = useState([]);
  const [tariffId, setTariffId] = useState('');
  const type = 'ALL';
  const [openedTenant, setOpenedTenant] = useState(null);
  const date = tenant.activation_date;

  const { myInfo } = useMyInfoRequest();

  const organizationName = useMemo(() => {
    return myInfo?.Organization?.name?.split(' /')[0] || '';
  }, [myInfo]);

  function matchTariff() {
    axios
      .post(
        `${baseUrl}/org/tariff/match`,
        { tariffId, tenantId: getTenantId(), type },
        {
          'Content-Type': 'application/json'
        }
      )
      .then(() => {
        refetchTenant();
      })
      .catch(e => {
        console.log(e.response);
      });
  }

  useEffect(() => {
    if (!tenantId) {
      axios
        .get(`${baseUrl}/org/tariff`)
        .then(response => {
          setTariffs(response.data.rows);
        })
        .catch(e => {
          console.log(e.response);
        });
    }
  }, [tenantId]);

  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 560,
      borderRadius: 12,
      backgroundColor: '#F2F4F5',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    }
  }));

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  function getModalStyle() {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-${50}%, -${50}%)`
    };
  }

  const handleOpen = item => {
    setOpenedTenant(item);
  };

  const handleClose = () => {
    setOpenedTenant(null);
  };

  function editTariff(item) {
    if (item === null) {
      return null;
    }

    return (
      <>
        <div style={modalStyle} className={classes.paper}>
          <span className="closemodal margin-bottom" onClick={handleClose}>
            <SVG
              className="closemodal"
              src={toAbsoluteUrl('/media/qwerty/closemodal.svg')}
            />
          </span>
          <div className="edit-tenant-tariff-container">
            <div>
              <FormattedMessage id="TARIFF.PLAN" />
              <br />
              <select
                name="tariff"
                value={tariffId}
                onChange={e => {
                  setTariffId(e.target.value);
                }}
                className="tenant-edit-tariff-select"
                style={{ display: 'block', width: '100%' }}
              >
                <option
                  key="0"
                  value={tenant.tariff_cold ? tenant.tariff_cold.id : null}
                >
                  {tenant.tariff_cold ? tenant.tariff_cold.name : null}
                </option>
                {tariffs.map(item =>
                  tenant.tariff_cold !== null &&
                  item?.name === tenant.tariff_cold?.name ? null : (
                    <option key={item.id} value={item.id}>
                      {item?.name}
                    </option>
                  )
                )}
              </select>
            </div>
            <DialogActions style={{ marginTop: 50 }}>
              <button onClick={handleClose} className="form-back-button">
                Back
              </button>
              <button
                onClick={() => {
                  handleClose();
                  matchTariff();
                }}
                className="form-next-button2"
              >
                Save
              </button>
            </DialogActions>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={`card card-custom tenant-details-div`}>
      <div className="card-body p-0 tenant-details-border">
        <div className="d-flex align-items-center justify-content-between flex-grow-1">
          <div className="d-flex flex-column">
            <span className="tenant-title">{tenant.name}</span>
            <p className="org-title">{organizationName || ''}</p>
            <p className="tenant-details">
              <FormattedMessage id="TENANT.DETAILS" />
            </p>
          </div>
        </div>
        <table className="w-100">
          <tbody>
            <tr>
              <td className="input-p id-padding">
                <FormattedMessage id="ID.NUMBER" />
              </td>
              <td className="tenant-info-det media-margin id-padding">
                {tenant.id_code}
              </td>
            </tr>
            <tr>
              <td className="input-p activat">
                <FormattedMessage id="ACTIVATION.DATE" />
              </td>
              <td className="tenant-info-det media-margin activat">
                {date && date.substr(0, 10)}
              </td>
            </tr>
            <tr>
              <td className="input-p">
                <FormattedMessage id="ADRESS" />
              </td>
              <td className="tenant-info-det media-margin address">
                {tenant.address}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-between flex-grow-1 details2">
        <div>
          <p className="input-p">
            <FormattedMessage id="TARIFF.PLAN" />
          </p>
        </div>
        <div>
          <span className="tenant-info-det t-details">
            <p>{tenant.tariff_cold ? tenant.tariff_cold.name : null}</p>
            {tenantId ? null : (
              <button onClick={handleOpen} className="edit-tariff-btn">
                Edit
              </button>
            )}
          </span>
        </div>
      </div>
      <Modal
        open={openedTenant !== null}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {editTariff(openedTenant !== null)}
      </Modal>
    </div>
  );
}
