// hooks
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

// components
import TariffPlan from './components/TariffPlan';
import BillingTenants from './components/BillingTenants';
import HeatingCalculation from './components/HeatingCalculation';
import CoolingCalculation from './components/CoolingCalculation';

// config
import useTariffsRequest from 'app/components/hooks/useTariffsRequest';

const BillingPage = () => {
  const [tariffsData, setTariffsData] = useState([]);
  const { tariffs, tariffsIsLoading } = useTariffsRequest();

  useEffect(() => {
    const getTariffs = () => {
      if (tariffs && !tariffsIsLoading) {
        const sortTariffs = tariffs.sort((a, b) =>
          a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
        );
        setTariffsData(sortTariffs);
      }
    };
    getTariffs();
  }, [tariffs, tariffsIsLoading]);

  return (
    <>
      <Helmet>
        <title>Billing</title>
      </Helmet>
      <div className="row">
        <div className="col-lg-6 col-xxl-6 col-md-6 col-sm-12 col-xs-12 margin-top-25">
          <HeatingCalculation tariffsData={tariffsData} className="gutter-b " />
        </div>
        <div className="col-lg-6 col-xxl-6 col-md-6 col-sm-12 col-xs-12 margin-top-25">
          <CoolingCalculation tariffsData={tariffsData} className="gutter-b " />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-xxl-3 col-xs-12">
          <TariffPlan className="card- gutter-b" />
        </div>
        <div className="col-lg-9 col-xxl-9 col-xs-12">
          <BillingTenants className="card- gutter-b" />
        </div>
      </div>
    </>
  );
};

export default BillingPage;
