/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as auth from '../_redux/authRedux';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AlertModal from 'app/components/modals/AlertModal';
import { Helmet } from 'react-helmet';
import { baseUrl } from 'app/config/baseUrl';

import '../components/login.scss';
import Button from '../../../components/button/Button';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState('');
  const [values, setValues] = useState({
    showPassword: false
  });
  const dispatch = useDispatch();

  // clear cache
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.removeQueries();
  }, []);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  function onSubmit(e) {
    setLoading(true);
    e.preventDefault();
    axios
      .post(`${baseUrl}/auth/login`, { username, password })
      .then(response => {
        setLoading(false);
        const token = response.data.token;
        localStorage.setItem('token', token);
        const userType = response.data.userType;
        let tenantId;
        if (response.data.Tenant !== null) {
          tenantId = response.data.Tenant.id;
        }

        dispatch(auth.actions.login(token));
        dispatch(
          auth.actions.fulfillUser({
            firstname: '',
            lastname: '',
            userType,
            tenantId
          })
        );
      })
      .catch(e => {
        setLoading(false);
        setAlert('Wrong Username or Password!');
        console.log(e);
      });
  }

  return (
    <div className="login-container">
      <Helmet>
        <title>Log In</title>
      </Helmet>
      {alert ? <AlertModal alert={alert} setAlert={setAlert} /> : null}
      <div className="text-center">
        <span className="authtitles">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </span>
      </div>
      <form className="login-form" onSubmit={onSubmit}>
        <div className="form-group">
          <span className="auth-2ndtitle">
            <FormattedMessage id="AUTH.INPUT.USERNAME" />
          </span>
          <input
            type="text"
            className="mt-3 auth-input"
            value={username}
            onChange={e => setUsername(e.target.value)}
            tabIndex={1}
          />
        </div>
        <div>
          <span className="auth-2ndtitle">
            <FormattedMessage id="AUTH.INPUT.PASSWORD" />
          </span>
          <Link to="/auth/forgot-password">
            <span className="forgot">
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </span>
          </Link>
        </div>
        <div className="show-password mt-3">
          <FilledInput
            inputProps={{ tabIndex: '2' }}
            type={values.showPassword ? 'text' : 'password'}
            disableUnderline
            style={{
              borderRadius: 12,
              width: '100%',
              backgroundColor: '#eef1f5',
              height: 50
            }}
            id="filled-adornment-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="start"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <Button
          label="AUTH.LOGIN.BUTTON"
          className="graybutton"
          loading={loading}
          type="submit"
        />
      </form>
    </div>
  );
}
