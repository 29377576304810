import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Grid, makeStyles, Modal } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

// components
import EditTariffPlanModal from './modals/EditTariffPlanModal';
import AddTariffPlanModal from './modals/AddTariffPlanModal';

// helpers
import { toAbsoluteUrl } from '_metronic/_helpers';

// config
import { baseUrl } from 'app/config/baseUrl';

// custom hooks
import useTariffsRequest from 'app/components/hooks/useTariffsRequest';

// assets
import TariffPlanImg from 'app/assets/icons/20.jpg';

export default function TariffPlan({ className }) {
  const [openEdit, setOpenEdit] = useState(null);
  const [editTariffId, setEditTariffId] = useState(null);
  const [tariffsData, setTariffsData] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const [openedTariff, setOpenedTariff] = useState(null);
  const { tariffs, refetchTariffs, tariffsIsLoading } = useTariffsRequest();

  useEffect(() => {
    const getTariffs = () => {
      if (tariffs && !tariffsIsLoading) {
        const sortTariffs = tariffs.sort((a, b) =>
          a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
        );
        setTariffsData(sortTariffs);
      }
    };
    getTariffs();
  }, [tariffs, tariffsIsLoading]);

  /* begin::Modal */
  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 586,
      borderRadius: 12,
      backgroundColor: '#F2F4F5',
      boxShadow: theme.shadows[5],
      marginTop: 30,
      padding: theme.spacing(2, 4, 3)
    },
    paper2: {
      position: 'absolute',
      width: 380,
      borderRadius: 12,
      backgroundColor: '#F2F4F5',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    root: {
      width: '100%'
    },
    backButton: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }));

  function getModalStyle() {
    return {
      top: `50%`,
      left: `50%`,
      transform: `translate(-${50}%, -${50}%)`
    };
  }

  const classes = useStyles();

  const handleOpenEdit = item => {
    setOpenEdit(item);
  };

  const handleCloseEdit = () => {
    setOpenEdit(null);
    setEditTariffId(null);
    refetchTariffs();
  };

  function editTariff(tariffItem) {
    if (tariffItem === null) {
      return null;
    }

    return (
      <div style={modalStyle} className={classes.paper2}>
        <span className="closemodal mb-10" onClick={handleCloseEdit}>
          <SVG
            className="closemodal"
            src={toAbsoluteUrl('/media/qwerty/closemodal.svg')}
          />
        </span>
        <div className="edittenantmodalcontainer">
          <img
            onClick={handleCloseEdit}
            className="tariff-lg-img"
            src={TariffPlanImg}
            alt="tariffplanimg"
          />
          <h3>
            <FormattedMessage id="NAME" />
          </h3>
          <p>{tariffItem.name}</p>
          <h3>
            <FormattedMessage id="FIXED.COST" />
          </h3>
          <p className="tariff-info-p">{tariffItem.fixed} ( ₾ )</p>
          <h3>
            <FormattedMessage id="ELECTRICITY.ADDON" />
          </h3>
          <p className="tariff-info-p">{tariffItem.el_addon} ( ₾ )</p>
          <button
            onClick={() => {
              setOpenEdit(null);
              setEditTariffId(tariffItem);
            }}
            type="button"
            className="edit-tariff-plan"
          >
            <FormattedMessage id="EDIT" />
          </button>
        </div>
      </div>
    );
  }
  // edit tenant end

  //Delete Tariff Plan

  const handleClose = () => {
    setOpenedTariff(null);
    refetchTariffs();
  };

  const handleOpen = item => {
    setOpenedTariff(item);
  };

  function onDelete() {
    axios
      .delete(`${baseUrl}/org/tariff/` + openedTariff.id)
      .then(() => {
        refetchTariffs();
      })
      .catch(e => {
        console.log(e.response);
      });
  }

  function deleteTariff(e) {
    if (e === null) {
      return null;
    }

    return (
      <div style={modalStyle} className={classes.paper}>
        <span className="closemodal" onClick={handleClose}>
          <SVG
            className="closemodal"
            src={toAbsoluteUrl('/media/qwerty/closemodal.svg')}
          />
        </span>
        <div className="deletetenantcontainer bg-white">
          <Box>
            <p className="delete-question">
              <FormattedMessage id="DELETE.TARIFF.QUES1" />
              <br />
              <FormattedMessage id="DELETE.TARIFF.QUES2" />
            </p>
            <hr />
          </Box>
          <Grid container spacing={2} className="deletebtngrid">
            <Grid item>
              <button
                className="delete-no"
                onClick={() => {
                  handleClose();
                }}
              >
                <FormattedMessage id="NO" />
              </button>
            </Grid>
            <Grid item>
              <button
                className="delete-yes"
                onClick={() => {
                  setOpenedTariff(null);
                  onDelete();
                }}
              >
                <FormattedMessage id="YES" />
              </button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0">
        <h3 className="card-title tabletitle flex-1">
          <FormattedMessage id="TARIFF.PLAN" />
        </h3>
        <AddTariffPlanModal fetch={refetchTariffs} />
      </div>
      <div className="card-body pt-2">
        {tariffsData.map(item => (
          <div key={item.id} className="d-flex align-items-center mb-10">
            <div className="symbol symbol-40 symbol-light-success mr-5">
              <span className="symbol-label">
                <img
                  alt="tenantimg"
                  className="tariffimg"
                  src={TariffPlanImg}
                />
              </span>
            </div>
            <div className="d-flex flex-column flex-grow-1 font-weight-bold">
              <span
                className="font-weight-bolder cursor-pointer"
                onClick={() => handleOpenEdit(item)}
              >
                {item.name}
              </span>
              <span className="text-muted">{item.el_addon.toFixed(2)}</span>
            </div>
            <div className="tariff-btns">
              <div className="tariffdiv">
                <span className="abcd">{item.name}</span>
              </div>
              <div
                onClick={() => handleOpen(item)}
                className="btn btn-icon btn-bckg hoverr btn-sm"
              >
                <span>
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/Trash-2.svg')}
                  />
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Delete tariff Plan */}
      <Modal
        open={openedTariff !== null}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {deleteTariff(openedTariff !== null)}
      </Modal>

      {/* Edit Tariff Plan */}
      {editTariff(openEdit) && (
        <Modal
          open={openEdit !== null}
          onClose={handleCloseEdit}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {editTariff(openEdit) || ''}
        </Modal>
      )}
      <EditTariffPlanModal
        tariffId={editTariffId}
        handleCloseEdit={handleCloseEdit}
      />
    </div>
  );
}
