// hooks
import React, { useState } from 'react';

// libs
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';

// components
import { Layout } from '../_metronic/layout';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import OrgDashboard from './pages/dashboards/OrgDashboard';
import TenantDashboard from './pages/dashboards/TenantDashboard';
import BillingContext from './components/context/BillingContext';

// pages
import AuthPage from './modules/Auth/pages/AuthPage';

// modules
import { Logout } from './modules/Auth';

export function Routes() {
  const lastMonth = moment()
    .subtract(1, 'months')
    .format('YYYY-MM');

  const [heatingDate, setHeatingDate] = useState(lastMonth);
  const [coolingDate, setCoolingDate] = useState(lastMonth);
  const [invoiceDate, setInvoiceDate] = useState(lastMonth);

  const { isAuthorized, userType } = useSelector(({ auth }) => {
    return {
      isAuthorized: auth.user != null,
      userType: auth.user ? auth.user.userType : null
    };
  }, shallowEqual);

  function renderDashboard(role) {
    if (role === 'TENANT') {
      return <TenantDashboard />;
    } else {
      return <OrgDashboard />;
    }
  }

  return (
    <BillingContext.Provider
      value={{
        heatingDate,
        setHeatingDate,
        coolingDate,
        setCoolingDate,
        invoiceDate,
        setInvoiceDate
      }}
    >
      <Switch>
        {!isAuthorized ? (
          <Route>
            <AuthPage />
          </Route>
        ) : !userType ? (
          localStorage.clear()
        ) : (
          <Redirect from="/auth" to="/" />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />

        {!isAuthorized ? (
          <Redirect to="/auth/login" />
        ) : (
          <Layout>{renderDashboard(userType)}</Layout>
        )}
      </Switch>
    </BillingContext.Provider>
  );
}
